import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import mapboxKmlToGeojson from '@mapbox/togeojson';
import {Uint8ArrayReader, Uint8ArrayWriter, ZipReader} from '@zip.js/zip.js';
import {Upload, message} from 'antd';
import {RcFile} from 'antd/lib/upload/interface';
import {FeatureCollection, Point, Polygon} from 'geojson';
import React, {useCallback, useEffect} from 'react';
import {UnreachableCaseError} from 'ts-essentials';
import {LngLat, geometryAreaSqM} from '../../../src/geo';
import {ImportedData, ImportedFarm, ImportedField} from '../../../src/gt-pack/gt-pack';
import {dedupeImportedData} from '../../../src/gt-pack/mergeImportedFarm';
import {sheetToGtPack} from '../../../src/gt-pack/sheetToGtPack';
import {
  TelepacContents,
  getTelepacReportPackage,
  xmlTelepacToGreenTriangle,
  zipTelepacToGreenTriangle,
} from '../../../src/gt-pack/telepac';
import {isPointOrPolygonFeature} from '../../../src/models/geojson';
import {CropMapping} from '../../../src/models/interfaces';
import {UnitSystem, convertArea, getCountryCodeGroups, getUnitSystem} from '../../../src/selectors/units';
import {fieldDesc, harvestDesc} from '../../../src/text/desc';
import {ReportableErrorI} from '../../../src/util/err-util';
import {roundedUnit} from '../../../src/util/roundDecimals';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {getStateOrm} from './getStateOrm';
import {EntityFormStage, GeojsonDataStage, UploadStage} from './stages';

interface ImportUploadFormProps {
  onImportedData: (x: EntityFormStage) => void;
}

// The part of the /import flow where the user uploads a file. "returns" either ImportedData or a FeatureCollection,
// for further processing.
export function ImportUploadForm({onImportedData}: ImportUploadFormProps) {
  const apis = useApis();
  const {t, store, authedFetcher} = apis;
  const [stage, setStage] = React.useState<UploadStage>({type: 'upload-data'});
  const beforeUpload = useCallback(
    (file: RcFile) => {
      setStage({type: 'loading'});
      processFile(apis, file)
        .then(res => {
          if (res.type == 'entity-form') {
            onImportedData(res);
          }
        })
        .catch(e => {
          const msg = e instanceof ReportableErrorI ? e.stringify(t) : t('UnknownErrorOccurred');
          message.error(msg);
          reportErr(e, 'ImportData');
          setStage({type: 'upload-data'});
        });
    },
    [apis, onImportedData, t],
  );
  const [instructions, setInstructions] = React.useState<{[name: string]: string}>({});
  useEffect(() => {
    const frenchUser = !!store.getState().dbMeta.userGroups.find(x => x.user_group == 'FRA');
    if (frenchUser) {
      authedFetcher({
        method: 'GET',
        path: 'authorize-s3',
        params: [['get', 'all-user-access/instructions-import-fr.pdf']],
      })
        .then(telepacInstructions => {
          if (telepacInstructions.length) {
            const instr = `Instructions pour l'utilisation de l'outil d'import`;
            setInstructions({[instr]: telepacInstructions[0]});
          }
        })
        .catch(e => {
          console.error(`Couldn't load telepac instructions:`, e);
        });
    }
  }, [authedFetcher, store]);

  if (stage.type == 'loading') {
    return (
      <h1 className="import-done">
        {t('Loading')} <LoadingOutlined spin />
      </h1>
    );
  } else if (stage.type == 'upload-data') {
    return (
      <div className="upload-form">
        <span>
          <span>
            <Upload beforeUpload={beforeUpload}>
              <button className="formy-input formy-submit">
                <UploadOutlined /> {t('ClickUpload')}
              </button>
            </Upload>
          </span>
          {Object.entries(instructions).map((x, idx) => (
            <a target="_blank" className="half-padding" href={x[1]} key={idx}>
              {x[0]}
            </a>
          ))}
        </span>
      </div>
    );
  } else {
    console.warn('Unknown stage', new UnreachableCaseError(stage));
  }
}

// Pre-processes the file into a GtPack or an intermediate format.
// Returns null if it's an unrecognized file type, in which case we should not store the file.
// If anything throws an error, we still save the file (on the assumption it was a recognized format but an invalid file)
// because it's useful for debugging.
async function preProcessFile(
  apis: Apis,
  fileBuf: ArrayBuffer,
  name: string,
  type: string,
): Promise<
  | null
  | {
      type: 'gt-pack';
      gtPack: ImportedData;
      telepac: null | TelepacContents;
    }
  | GeojsonDataStage
> {
  if (type === 'application/zip' || type == 'application/x-zip-compressed') {
    const reader = new ZipReader(new Uint8ArrayReader(new Uint8Array(fileBuf)));
    const entries = await reader.getEntries();
    const files = await Promise.all(
      entries.map(async x => ({path: x.filename, data: await x.getData!(new Uint8ArrayWriter())})),
    );
    // TODO(savv): consider adding support for non-telepac Shapefiles, returning GeojsonDataStage
    const result = await zipTelepacToGreenTriangle(files, await getTelepacCropMapping(apis));
    return {type: 'gt-pack', gtPack: result.data, telepac: result.contents};
  } else if (type === 'application/xml' || type === 'text/xml') {
    const text = new TextDecoder().decode(fileBuf);
    const result = await xmlTelepacToGreenTriangle(text, await getTelepacCropMapping(apis));
    return {type: 'gt-pack', gtPack: result.data, telepac: result.contents};
  } else if (
    type == 'application/json' ||
    name.toLocaleLowerCase().endsWith('.json') ||
    name.toLocaleLowerCase().endsWith('.geojson')
  ) {
    const text = new TextDecoder().decode(fileBuf);
    const json = JSON.parse(text);
    if (json?.type == 'FeatureCollection') {
      return {type: 'geojson', data: json};
    } else if (json?.farms) {
      return {type: 'gt-pack', gtPack: new ImportedData(json), telepac: null};
    } else {
      message.error(apis.t('Error'));
      throw new Error('Unrecognized JSON format: ' + Object.keys(json || {}).join(','));
    }
  } else if (type == 'application/vnd.google-earth.kml+xml' || name.toLocaleLowerCase().endsWith('.kml')) {
    const text = new TextDecoder().decode(fileBuf);
    return {type: 'geojson', data: kmlToGeojson(text)};
  } else if (name.toLocaleLowerCase().endsWith('.xlsx') || name.toLocaleLowerCase().endsWith('.xls')) {
    return {type: 'gt-pack', gtPack: sheetToGtPack(fileBuf), telepac: null};
  } else {
    message.error(apis.t('SelectPac'));
    return null;
  }
}

async function processFile(apis: Apis, file: RcFile): Promise<EntityFormStage> {
  const fileBuf = await file.arrayBuffer();
  let res;
  try {
    res = await preProcessFile(apis, fileBuf, file.name, file.type);
  } catch (e) {
    await saveImportedFile(apis, file.name, fileBuf);
    message.error(apis.t('Error'));
    throw e;
  }

  if (res) {
    await saveImportedFile(apis, file.name, fileBuf);
  } else {
    message.error(apis.t('SelectPac'));
    throw new Error('Unknown file type: ' + file.type);
  }

  const gtPack = res.type == 'gt-pack' ? res.gtPack : geojsonToGtPack(getUnitSystem(apis.store.getState()), res.data);

  const email = apis.store.getState().dbMeta.curUser!.email;
  const stateOrm = getStateOrm(apis.store, apis);
  const warnings = await dedupeImportedData(email, stateOrm, gtPack);

  for (const warning of warnings) {
    let msg = '';
    const state = apis.store.getState();
    const t = apis.t;
    if (warning.type == 'DuplicateHarvestWarning') {
      msg =
        t('DuplicateEntryWasRemoved') +
        ' ' +
        (warning.field
          ? fieldDesc(t, state.crops.crops, getCountryCodeGroups(state), warning.field, warning.harvest)
          : harvestDesc(t, state.crops.crops, warning.harvest, null, getCountryCodeGroups(state)));
    } else if (warning.type == 'DuplicateFarmReferenceWarning') {
      msg = t('DuplicateEntryWasRemoved') + ' ' + warning.external_farm_id;
    } else if (warning.type == 'DuplicateFieldReferenceWarning') {
      msg = t('DuplicateEntryWasRemoved') + ' ' + warning.external_farm_id + '/' + warning.external_field_id;
    } else if (warning.type == 'DuplicatePolicyNumberWarning') {
      msg = t('DuplicateEntryWasRemoved') + ' ' + warning.policy_number;
    } else if (warning.type == 'DuplicateClaimWarning') {
      msg = t('DuplicateEntryWasRemoved') + ' ' + warning.claim_number;
    } else {
      console.error(new UnreachableCaseError(warning));
    }

    message.warn(msg, 10);
  }

  const telepacContents = res.type == 'gt-pack' ? res.telepac : null;
  const telepacData =
    telepacContents &&
    (await getTelepacReportPackage(stateOrm, apis.store.getState().crops.crops, telepacContents, gtPack));
  return {type: 'entity-form', initialValues: gtPack, telepacData};
}

async function saveImportedFile(apis: Apis, filename: string, fileBuf: string | ArrayBuffer) {
  filename = new Date(apis.clock.now()).toISOString() + '-' + filename;
  console.info('Saving imported file to', filename);
  apis.analytics.logEvent({
    event_name: 'saveImportedFile',
    props: {filename},
  });
  apis
    .authedFetcher({
      method: 'PUT',
      path: 'photo/imported-files/' + filename,
      request_body: fileBuf,
    })
    .catch(e => reportErr(e, 'upload-imported-file'));
}

async function getTelepacCropMapping(apis: Apis) {
  const result = await apis.authedFetcher({
    method: 'GET',
    path: 'api/crop_mapping',
    params: [['identifier', 'eq.FRA-PAC']],
  });
  return Object.fromEntries(result.map((x: CropMapping) => [x.origin_id, x.crop_id]));
}

interface GeojsonImportMapperProps {
  data: GeoJSON.FeatureCollection;
  onImportedData: (x: EntityFormStage) => void;
}

// When the user uploads a FeatureCollection (which could also be as a KML, Shapefile), then this is equivalent to a
// list of fields, each having a properties that can be used to fill the import form.
//
// By way of illustration, let's say that the user uploads a KML, containing three features:
// Polygon feature with props: {NOM_FARMA: 'Chris farm', REF_FARM: 5, culture: 123}
// Polygon feature with props: {NOM_FARMA: 'Greg farm', REF_FARM: 7, culture: 456}
// Point feature: {NOM_FARMA: 'Seb farm', REF_FARM: 10, culture: 123}
//
// This form lets the user select which property corresponds to which:
// - Farm reference: let the user select one of keys of the feature properties.
//   E.g.: the user selects REF_FARM; we will then do a group by on the features, and create N farms.
// - Farm name: Similarly, except we don't use the name in grouping.
// - Field reference: let the user select one of keys of the feature properties.
// - Crop Id: lets the user select one of keys of the feature properties; then, in a following section, ask the user to
//   map each unique value (e.g. 123, 456) to an internal crop_id.
// - Harvest Year: lets the user select one of the keys, but only if those keys contain harvest years or null;
//   or alternatively, it lets the user select an actual year.
// noinspection JSUnusedLocalSymbols
function GeojsonImportMapper({onImportedData, data}: GeojsonImportMapperProps) {
  // TODO(savv): implement this.
  return null;
}

function kmlToGeojson(kmlText: string): FeatureCollection<Polygon | Point> {
  let kml = new DOMParser().parseFromString(kmlText, 'application/xml');
  const geojson: FeatureCollection = mapboxKmlToGeojson.kml(kml);
  for (const feature of geojson.features) {
    if (feature.geometry.type == 'Polygon') {
      for (const ring of feature.geometry.coordinates) {
        for (const point of ring) {
          if (point.length == 3) {
            point.pop();
          }
        }
      }
    }
  }

  return {
    type: 'FeatureCollection',
    features: geojson.features.filter(isPointOrPolygonFeature),
  };
}

// TODO(savv): This function allows us to implement hacks to "understand" Geojson objects. Replace this with GeojsonImportMapper.
function geojsonToGtPack(units: UnitSystem, geojson: FeatureCollection<Point | Polygon>): ImportedData {
  const data = new ImportedData(null);
  data.farms.push(new ImportedFarm(null));
  for (const f of geojson.features) {
    const field_shape = f.geometry.type == 'Polygon' ? (f.geometry as Polygon) : null;
    const field_area =
      field_shape &&
      roundedUnit(convertArea(units.areaUnit, {val: geometryAreaSqM(field_shape) / 10000, unit: 'hectares'}));
    const field_location =
      f.geometry.type == 'Point' ? ([f.geometry.coordinates[0], f.geometry.coordinates[1]] as LngLat) : null;
    data.farms[0].fields.push(
      new ImportedField({
        field_shape,
        field_area,
        field_location,
        // This is a hack to facilitate importing Agrobrasil fields. We don't need to keep it
        // once GeojsonImportMapper is implemented.
        external_field_id: f.properties?.external_field_id ?? f.properties?.name ?? f.properties?.description,
      }),
    );
  }

  return data;
}
