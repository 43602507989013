import {insertPolicy, updateHarvest, updatePolicy} from '../../../src/redux/actions/db';
import {getPolicyFilter} from '../../../src/selectors/harvest';
import {remove} from '../../../src/util/arr-util';
import {fetchEntitiesBy} from '../../../src/util/fetchEntity';
import {Apis} from '../apis/Apis';
import {PolicyForm} from '../edit/AddOrEditPolicy';

export async function savePolicyAndRelatedHarvests(apis: Apis, policyForm: PolicyForm) {
  const {farm_id, policy_id: policyId, harvest_year, harvest_forms, user_group, policy_number, ...policy} = policyForm;

  // User group and policy number are checked in the validator and shouldn't occur.
  if (!user_group) {
    throw new Error('User group is required');
  }
  if (!policy_number) {
    throw new Error('Policy number is required');
  }
  const policyData = {...policy, user_group, policy_number};

  let policy_id = policyId;
  if (!policy_id) {
    policy_id = apis.store.dispatch(insertPolicy(apis, policyData));
  } else {
    apis.store.dispatch(updatePolicy(apis, policy_id, policyData));
  }

  // Update values and set policy relation for all selected farm level harvests.
  for (const harvestForm of harvest_forms) {
    // Harvest_id is checked in the validator and this shouldn't occur.
    if (!harvestForm.harvest_id) {
      throw new Error('Policy number is required');
    }
    apis.store.dispatch(
      updateHarvest(harvestForm.harvest_id, {
        policy_id,
        commodity_price: harvestForm.commodity_price,
        premium_rate_percent: harvestForm.premium_rate_percent,
        reference_yield: harvestForm.reference_yield,
        insured_percent: harvestForm.insured_percent,
      }),
    );
  }

  // Set policy relation for all selected field level harvests.
  const selectedFieldLevelHarvestIds = harvest_forms.flatMap(form => form.selectedFieldLevelHarvestIds);
  selectedFieldLevelHarvestIds.forEach(fieldLevelHarvestId =>
    apis.store.dispatch(updateHarvest(fieldLevelHarvestId, {policy_id})),
  );

  const selectedFarmLevelHarvestIds = harvest_forms.map(harvest => harvest.harvest_id).filter(remove.nulls);
  const query = [getPolicyFilter(policy_id)];
  const allSelectedHarvests = [...selectedFarmLevelHarvestIds, ...selectedFieldLevelHarvestIds];
  if (allSelectedHarvests.length) {
    query.push({
      not: {
        column: 'harvest_id',
        operator: 'in',
        value: allSelectedHarvests,
      },
    });
  }
  const harvestsToRemoveFromPolicy = await fetchEntitiesBy(apis.authedFetcher, 'harvest', query);
  // Remove policy relation from all harvests that are no longer selected.
  for (const harvestToRemove of harvestsToRemoveFromPolicy) {
    apis.store.dispatch(updateHarvest(harvestToRemove.harvest_id, {policy_id: null}));
  }
}
