import {grAllGroups} from '../constants/group-consts';
import {I18nFunction, I18nSimpleKey} from '../i18n/i18n';
import {
  Claim,
  ClaimDamage,
  ClaimStatus,
  Farm,
  Loss,
  Policy,
  VisitCandidate,
  VisitCandidateStatus,
  VisitLite,
} from '../models/interfaces';
import {getDistinctByKey, remove, uniqueByFastDeepEqual} from './arr-util';

export type VisitWithClaim = VisitLite & {claim: Claim | null; claim_damage: ClaimDamage[]};

export function extractInsuranceLossesFromVisitWithClaim(visit: VisitWithClaim): Loss[] {
  return extractInsuranceLosses(visit.claim_damage);
}

export function extractInsuranceLosses(claimDamages: ClaimDamage[]): Loss[] {
  return uniqueByFastDeepEqual(
    claimDamages.flatMap(claimDamage => claimDamage.insurance_loss_estimation).filter(remove.nulls),
  );
}

export function extractAssignedTo(
  assignedTo: Claim['assigned_to'] | undefined,
  statuses: null | VisitCandidateStatus[],
) {
  return (
    assignedTo
      ?.filter(
        assigned =>
          assigned?.visit_candidate_status && (!statuses || statuses.includes(assigned.visit_candidate_status)),
      )
      .filter(remove.nulls) ?? []
  );
}

export function extractAssignedToEmails(claim: Claim | null, statuses: null | VisitCandidateStatus[]): string[] {
  return (
    extractAssignedTo(claim?.assigned_to, statuses)
      .map(assigned => assigned?.email)
      .filter(remove.nulls) ?? []
  );
}

export function createVisitCandidates(emails: string[], status: VisitCandidateStatus) {
  return emails.map<VisitCandidate>(email => {
    return {
      email,
      visit_candidate_status: status,
      updated_at: null,
    };
  });
}

// assigned_to can be changed on the claims web view, claims app view and visit app view.
// - claims on the web: default status is assigned and LAs will either accept or reject it on the claims app view
// - visits in the app: default status is accepted and there is no notion of assigning and rejecting claims (visits)
// - claims in the app: claims can be accepted or rejected and are directly saved as such to db (not by this function)
// In any case, we must always preserve the rejected ones that didn't potentially change to assigned or accepted.
export function getUpdatedClaimAssignedTo(
  currentAssignedTo: Claim['assigned_to'],
  updatedAssignedTo: Claim['assigned_to'],
) {
  const updatedAssignedToEmails = updatedAssignedTo?.map(assignedTo => assignedTo?.email).filter(remove.nulls) ?? [];

  // keep only rejected harvests that are not in updated
  const existingAssignedTo = (currentAssignedTo ?? []).filter(
    (assignedTo: VisitCandidate | null) =>
      assignedTo?.email &&
      assignedTo.visit_candidate_status === 'rejected' &&
      !updatedAssignedToEmails.includes(assignedTo.email),
  );

  // always add all updatedAssignedTo and ensure uniqueness across email and status
  return getDistinctByKey([...existingAssignedTo, ...(updatedAssignedTo ?? [])], candidate =>
    JSON.stringify({email: candidate.email, visit_candidate_status: candidate.visit_candidate_status}),
  );
}

export type ClaimItem = Claim & {
  farm: null | Farm;
  policy: Policy | null;
  visits: VisitLite[];
  claimDamages: ClaimDamage[];
};

export function getClaimDamageVisitState(visits: VisitLite[]): 'New' | 'Closed' | 'Ongoing' {
  if (visits.length === 0) {
    return 'New';
  }
  if (visits.some(v => v.closed && v.visit_type === 'claims-visit')) {
    return 'Closed';
  }
  return 'Ongoing';
}

function genericDesc(
  t: I18nFunction,
  desc: I18nSimpleKey,
  optionalDesc: (I18nSimpleKey | string | null | undefined)[] = [],
): string {
  const optional = optionalDesc.filter(remove.nulls);
  return t(desc) + (optional.length ? ` (${optional.map(k => t(k as I18nSimpleKey)).join(', ')})` : '');
}

export function descClaimDamageVisitState(t: I18nFunction, visits: VisitLite[]): string {
  const latestVisit: VisitLite | undefined = visits
    .sort((a, b) => (a.visit_date ?? '').localeCompare(b.visit_date ?? ''))
    .reverse()[0];
  return genericDesc(t, getClaimDamageVisitState(visits), [latestVisit?.visit_type]);
}

// TODO(seb): Add an "unopened" state. (For the visit list we can simply use the presence of a visit_id in VisitState)
export const SimplifiedClaimStatus = ['New', 'Ongoing', 'Done'] as const;
export type SimplifiedClaimStatus = (typeof SimplifiedClaimStatus)[number];
export const claimStatusToSimplified: Record<ClaimStatus, SimplifiedClaimStatus> = {
  'created-unassigned': 'New',
  'awaiting-adjuster-acceptance': 'New',
  // For some customers, adjuster-accepted is set upon import, so the claim is still new to the user.
  // To simplify the claim status mapping, we assume it's "New" for everyone.
  'adjuster-accepted': 'New',
  'visit-date-defined': 'Ongoing',
  'visit-samples-collected': 'Ongoing',
  'visit-report-created': 'Ongoing',
  'assessment-in-progress': 'Ongoing',
  'payment-proposal-sent': 'Ongoing',
  completed: 'Done',
  rejected: 'Done',
};
export const simplifiedToClaimStatus: Record<SimplifiedClaimStatus, ClaimStatus[]> = Object.entries(
  claimStatusToSimplified,
).reduce(
  (acc, [k, v]) => {
    acc[v] = acc[v] || [];
    acc[v].push(k as ClaimStatus);
    return acc;
  },
  {} as Record<SimplifiedClaimStatus, ClaimStatus[]>,
);

export function getCustomClaimStatus(
  t: I18nFunction,
  farmOrPolicy: Pick<Farm, 'user_group'> | null,
  claimItem: Pick<Claim, 'status'> | null,
): string | null {
  if (!farmOrPolicy || !claimItem) {
    return null;
  }
  if (grAllGroups.includes(farmOrPolicy.user_group)) {
    return claimItem.status ? t(claimStatusToSimplified[claimItem.status]) : null;
  }
  return t(('ClaimStatus_' + claimItem.status) as `ClaimStatus_${ClaimStatus}`);
}
