import {TelepacHarvestContents} from '../gt-pack/telepac';
import {FarmReportValues, ReportSignature, VisitReportOptions} from '../report/report-types';
import {isBool, isNum} from '../validator-constraints';
import {CoverageType, LossCause, VisitType} from './interfaces';

// TODO(kristjan): Move to SQL and consequently to interfaces.ts
export const Gender = ['male', 'female'] as const;
export type Gender = (typeof Gender)[number];

export const MaritalStatus = ['single', 'married', 'divorced', 'widowed', 'separated'] as const;
export type MaritalStatus = (typeof MaritalStatus)[number];

export const InsuredStatus = ['verified', 'unverified'] as const;
export type InsuredStatus = (typeof InsuredStatus)[number];

// Sandy soil, with clay content between 10% and 15%
// Medium textured soil, with clay content between 15% and 35%
// Clay soil, with a clay content greater than 35%
export const SoilType = ['sandy', 'medium', 'clay'] as const;
export type SoilType = (typeof SoilType)[number];

export const PlantingType = ['conventional', 'direct'] as const;
export type PlantingType = (typeof PlantingType)[number];

export const ProductType = ['costing', 'productivity'] as const;
export type ProductType = (typeof ProductType)[number];

export const LegalEntityType = ['person', 'company'] as const;
export type LegalEntityType = (typeof LegalEntityType)[number];

export const ProductionUnit = ['brl-per-hectare'] as const;
export type ProductionUnit = (typeof ProductionUnit)[number];

export interface ProductionValue {
  val: number;
  unit: ProductionUnit;
}

export class FarmCustomColumns {
  address?: Address | null;

  constructor(x: Partial<FarmCustomColumns> | null) {
    this.address = x?.address ?? null;
  }
}

export interface Address {
  street: string | null;
  number: string | null;
  additional_information: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  // Refers to country specific code (e.g. IBGE, INSEE...) that is used beside the zipcode
  external_code: string | null;
  country: string | null;
}

// Insured entity can be either a company or person.
export class LegalEntity {
  type: LegalEntityType | null;

  // External id is a unique identification number (e.g. tax number).
  // In Brazil, it's either CNPJ for companies or CPF for persons.
  external_id: string | null;
  address: Address | null;
  email: string | null;
  phone: string | null;
  gender: Gender | null;
  name: string | null;
  alternative_name: string | null;
  inception_date: string | null;
  insured_status: InsuredStatus | null;
  marital_status: MaritalStatus | null;

  constructor(x: Partial<LegalEntity> | null) {
    this.type = x?.type ?? null;
    this.external_id = x?.external_id ?? null;
    this.address = x?.address ?? null;
    this.email = x?.email ?? null;
    this.phone = x?.phone ?? null;
    this.gender = x?.gender ?? null;
    this.alternative_name = x?.alternative_name ?? null;
    this.name = x?.name ?? null;
    this.inception_date = x?.inception_date ?? null;
    this.insured_status = x?.insured_status ?? null;
    this.marital_status = x?.marital_status ?? null;
  }
}

export class Broker {
  entity: LegalEntity | null;
  registration_number: string | null;
  leader: boolean | null;
  percent: number | null;

  constructor(x: Partial<Broker> | null) {
    this.entity = x?.entity ?? null;
    this.registration_number = x?.registration_number ?? null;
    this.leader = x?.leader ?? null;
    this.percent = x?.percent ?? null;
  }
}

export class Beneficiary {
  entity: LegalEntity | null;
  registration_number: string | null;
  percent: number | null;

  constructor(x: Partial<Beneficiary> | null) {
    this.entity = x?.entity ?? null;
    this.registration_number = x?.registration_number ?? null;
    this.percent = x?.percent ?? null;
  }
}

export class MgaPolicyCustomColumns {
  // Proposal is initially created with either id from the external system (e.g. eCampo) or locally generated id.
  // If the proposal is approved, it is sent to multiple external systems (subsidies, insurance companies) and each
  // of them has their own id below:
  external_proposal_id: string | null;
  external_policy_id: string | null;
  federal_subsidy_id: string | null;

  product_type: ProductType | null;

  // SUSEP code for the product. SUSEP is a department in the Brazilian government that regulates insurance in the country.
  product_code: string | null;

  // Culture code from the Brazilian central bank.
  culture_code: string | null;

  // Subsidy unit is Brazil Reals R$.
  federal_subsidy: number | null;
  state_subsidy: number | null;

  // The following attributes are based on 27th December 2024 AXA's policy template.
  rural_credit: boolean | null;
  previous_crop: string | null;
  crop_already_planted: boolean | null;
  crop_already_damaged: boolean | null;
  different_invoice_recipient: boolean | null;
  aware_of_zoagro_mapa: boolean | null;
  all_existing_fields_insured: boolean | null;
  preexisting_policy_for_same_area: boolean | null;
  first_or_second_year: boolean | null;
  planting_type: PlantingType | null;

  // The following attributes were not strictly required by AXA, but are present in eCampo, and we still collect them.
  years_of_agriculture: number | null;
  climatic_event_past_5_years: boolean | null;
  certified_or_registered_seeds: boolean | null;
  drainage_problems: boolean | null;
  flood_or_waterlogging_past_5_years: boolean | null;
  // Production cost and sum insured are not strictly needed yet, but they are available in the payload and
  // may be useful for validation.
  production_cost: ProductionValue | null;
  sum_insured: number | null;

  constructor(x: Partial<MgaPolicyCustomColumns> | null) {
    this.external_proposal_id = x?.external_proposal_id ?? null;
    this.external_policy_id = x?.external_policy_id ?? null;
    this.product_type = x?.product_type ?? null;

    this.culture_code = x?.culture_code ?? null;
    this.product_code = x?.product_code ?? null;

    this.federal_subsidy_id = x?.federal_subsidy_id ?? null;

    this.federal_subsidy = x?.federal_subsidy ?? null;
    this.state_subsidy = x?.state_subsidy ?? null;

    this.rural_credit = x?.rural_credit ?? null;
    this.previous_crop = x?.previous_crop ?? null;
    this.crop_already_planted = x?.crop_already_planted ?? null;
    this.crop_already_damaged = x?.crop_already_damaged ?? null;
    this.different_invoice_recipient = x?.different_invoice_recipient ?? null;
    this.aware_of_zoagro_mapa = x?.aware_of_zoagro_mapa ?? null;
    this.all_existing_fields_insured = x?.all_existing_fields_insured ?? null;
    this.preexisting_policy_for_same_area = x?.preexisting_policy_for_same_area ?? null;
    this.first_or_second_year = x?.first_or_second_year ?? null;
    this.planting_type = x?.planting_type ?? null;

    this.years_of_agriculture = x?.years_of_agriculture ?? null;
    this.climatic_event_past_5_years = x?.climatic_event_past_5_years ?? null;
    this.certified_or_registered_seeds = x?.certified_or_registered_seeds ?? null;
    this.drainage_problems = x?.drainage_problems ?? null;
    this.flood_or_waterlogging_past_5_years = x?.flood_or_waterlogging_past_5_years ?? null;
    this.production_cost = x?.production_cost ?? null;
    this.sum_insured = x?.sum_insured ?? null;
  }
}

// We allow everything to be null to avoid unnecessarily pollution of the custom columns.
export class PolicyCustomColumns {
  insured: LegalEntity | null;
  representative: LegalEntity | null;
  valid_from: string | null;
  valid_to: string | null;
  coverage_type: CoverageType | null;
  brokers: Broker[] | null;
  beneficiaries: Beneficiary[] | null;
  mga: MgaPolicyCustomColumns | null;

  constructor(x: Partial<PolicyCustomColumns> | null) {
    this.insured = x?.insured ?? null;
    this.representative = x?.representative ?? null;
    this.valid_from = x?.valid_from ?? null;
    this.valid_to = x?.valid_to ?? null;
    this.coverage_type = x?.coverage_type ?? null;
    this.brokers = x?.brokers ?? null;
    this.beneficiaries = x?.beneficiaries ?? null;
    this.mga = x?.mga ?? null;
  }
}

export class FieldCustomColumns {
  constructor(x: null | any) {}
}

export type AffectedZone = {
  // TODO(seb): Remove optionality after Q2 2024.
  inseeCode?: string;
  identifiers: string[];
  affected: boolean;
  area: number;
  lossCauses: LossCause[];
  lossLabels: string[];
};

export class EtlCustomColumns {
  franchises: string[] | null;
  cropCode: string;
  cropInfoCode: string;
  cngraCode: string;

  constructor(x: null | any) {
    this.franchises = getStrArray(x, 'franchises');
    this.cropCode = x?.cropCode ?? null;
    this.cropInfoCode = x?.cropInfoCode ?? null;
    this.cngraCode = x?.cngraCode ?? null;
  }
}

export class MgaHarvestCustomColumns {
  soil_type: SoilType | null;
  expected_planting_date: string | null;

  constructor(x: Partial<MgaHarvestCustomColumns> | null) {
    this.soil_type = x?.soil_type ?? null;
    this.expected_planting_date = x?.expected_planting_date ?? null;
  }
}

export class HarvestCustomColumns {
  grpmFranchises: string[] | null;
  grpmPastYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAvgYield: number | null;
  grpmUpdatedYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAffectedZones: AffectedZone[] | null;
  grpmCulture: string | null;
  grpmCropDetails: {
    Espece: string | null;
    Variete: string | null;
    Saisonnalite: string | null;
    DetailRegional: string | null;
    Couleur: string | null;
    ModeDeProduction: string | null;
    Destination: string | null;
  } | null;

  etl: EtlCustomColumns | null;
  telepacData: TelepacHarvestContents | null;
  mga: MgaHarvestCustomColumns | null;

  constructor(x: null | any) {
    Object.assign(this, x);
    this.grpmFranchises = getStrArray(x, 'grpmFranchises');
    this.grpmPastYields = x?.grpmPastYields ?? null;
    if (this.grpmPastYields?.length != 5 || this.grpmPastYields.some(x => !isNum(x))) {
      this.grpmPastYields = null;
    }
    this.grpmUpdatedYields = x?.grpmUpdatedYields ?? null;
    if (this.grpmUpdatedYields?.length != 5 || this.grpmUpdatedYields.some(x => !isNum(x))) {
      this.grpmUpdatedYields = null;
    }
    this.grpmAvgYield = getNum(x, 'grpmAvgYield');
    this.grpmAffectedZones = x?.grpmAffectedZones ?? null;
    if (this.grpmAffectedZones) {
      if (
        !this.grpmAffectedZones.every(y => getStrArray(y, 'identifiers') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossCauses') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossLabels') != null) ||
        !this.grpmAffectedZones.every(y => isBool(y?.affected)) ||
        !this.grpmAffectedZones.every(y => getNum(y, 'area') != null)
      ) {
        this.grpmAffectedZones = null;
      }
    }
    this.telepacData = x?.telepacData ?? null;
    this.grpmCulture = getStr(x, 'grpmCulture');
    this.grpmCropDetails = x?.grpmCropDetails
      ? {
          Espece: getStr(x.grpmCropDetails, 'Espece'),
          Variete: getStr(x.grpmCropDetails, 'Variete'),
          Saisonnalite: getStr(x.grpmCropDetails, 'Saisonnalite'),
          DetailRegional: getStr(x.grpmCropDetails, 'DetailRegional'),
          Couleur: getStr(x.grpmCropDetails, 'Couleur'),
          ModeDeProduction: getStr(x.grpmCropDetails, 'ModeDeProduction'),
          Destination: getStr(x.grpmCropDetails, 'Destination'),
        }
      : null;

    this.etl = x?.etl ? new EtlCustomColumns(x.etl) : null;
    this.mga = x?.mga ? new MgaHarvestCustomColumns(x.mga) : null;
  }
}

function getStrArray(x: null | undefined | Record<string, any>, prop: string): null | string[] {
  const val = x?.[prop];
  if (val instanceof Array && val.every(x => typeof x == 'string')) {
    return val;
  }

  return null;
}

function getNum(x: null | undefined | Record<string, any>, prop: string): null | number {
  const val = x?.[prop];
  if (typeof val == 'number') {
    return val;
  }

  return null;
}

function getStr(x: null | undefined | Record<string, any>, prop: string): null | string {
  const val = x?.[prop];
  if (typeof val == 'string') {
    return val;
  }
  return null;
}

export const GrpmVisitAction = [
  'preinspection-none',
  'validation-without-review',
  'validation-with-review',
  'monitoring-without-review',
  'monitoring-without-review-provisioning',
  'monitoring-with-review',
  'monitoring-with-review-provisioning',
  'claim-some',
  'claim-all',
  'claim-delivery',
] as const;
export type GrpmVisitAction = (typeof GrpmVisitAction)[number];

export const GrpmVisitTypeToActionMapping: Record<VisitType, GrpmVisitAction[]> = {
  'preinspection-visit': ['preinspection-none'],
  'validation-visit': ['validation-without-review', 'validation-with-review'],
  'monitoring-visit': [
    'monitoring-without-review',
    'monitoring-without-review-provisioning',
    'monitoring-with-review',
    'monitoring-with-review-provisioning',
  ],
  'claims-visit': ['claim-some', 'claim-all', 'claim-delivery'],
};

export class VisitCustomColumnsGrpm {
  visitAction: GrpmVisitAction | null;
  inseeShapes: Record<string, string>;

  constructor(x: Partial<VisitCustomColumnsGrpm> | null) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
  }
}

export class VisitCustomColumns {
  grpm: null | VisitCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];
  // The app version used when signing the report.
  signedOnVersion?: string;

  constructor(x: Partial<VisitCustomColumns> | null) {
    this.grpm = x?.grpm ? new VisitCustomColumnsGrpm(x.grpm) : null;
    this.signedFarmReportValues = x?.signedFarmReportValues;
    this.signedVisitReportOptions = x?.signedVisitReportOptions;
    this.signedSignatures = x?.signedSignatures;
    this.signedOnVersion = x?.signedOnVersion;
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): VisitCustomColumns {
    return new VisitCustomColumns({
      grpm: this.grpm,
    });
  }
}

export class ClaimCommentGrpm {
  issued_on: string;
  text: string;

  constructor(x: null | any) {
    this.issued_on = x?.issued_on;
    this.text = x?.text;
  }
}

export class ClaimCustomColumnsGrpm {
  visitAction: GrpmVisitAction | null;
  inseeShapes: Record<string, string>;
  comments: ClaimCommentGrpm[];

  constructor(x: Partial<ClaimCustomColumnsGrpm> | null) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
    this.comments = x?.comments ?? [];
  }
}

export class ClaimCustomColumns {
  grpm: null | ClaimCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];
  // The app version used when signing the report.
  signedOnVersion?: string;

  constructor(x: Partial<ClaimCustomColumns> | null) {
    this.grpm = x?.grpm ? new ClaimCustomColumnsGrpm(x.grpm) : null;
    this.signedFarmReportValues = x?.signedFarmReportValues;
    this.signedVisitReportOptions = x?.signedVisitReportOptions;
    this.signedSignatures = x?.signedSignatures;
    this.signedOnVersion = x?.signedOnVersion;
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): ClaimCustomColumns {
    return new ClaimCustomColumns({
      grpm: this.grpm,
    });
  }
}
