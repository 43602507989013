import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {Button, Col, Divider, PageHeader, Row} from 'antd';
import React, {CSSProperties, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {ClockI} from '../../../src/Clock';
import {FormyErrors, FormyI} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {useFormy, useFormyValue} from '../../../src/Formy/hooks';
import {CROP_COLORS} from '../../../src/constants/colors';
import {I18nParametric, I18nSimpleKey} from '../../../src/i18n/i18n';
import {
  Beneficiary,
  Broker,
  Gender,
  LegalEntity,
  MgaPolicyCustomColumns,
  PolicyCustomColumns,
} from '../../../src/models/CustomColumns';
import {
  Field,
  Harvest,
  HarvestYear,
  Policy,
  PolicyStatus,
  RequestStatus,
  UnitPriceUnit,
  YieldUnit,
} from '../../../src/models/interfaces';
import {OmitDbColumns, Uuid} from '../../../src/models/types';
import {queueDeleteMutation, queueUpdateMutation} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getBaseCrop} from '../../../src/selectors/crops';
import {getPolicyFilter} from '../../../src/selectors/harvest';
import {harvestAggregationKeyEq} from '../../../src/selectors/harvest-key';
import {convertArea, convertToMonetaryUnit, getCountryCodeGroups} from '../../../src/selectors/units';
import {getCurYear} from '../../../src/selectors/year';
import {fieldDesc, harvestDesc} from '../../../src/text/desc';
import {aggregate, remove} from '../../../src/util/arr-util';
import {fetchEntitiesBy, fetchEntity} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {Nullable} from '../../../src/util/types';
import {isArr, isStr} from '../../../src/validator-constraints';
import {FormyBool} from '../Formy/FormyBool';
import {FormyDatePicker} from '../Formy/FormyDatePicker';
import {FormyDateRange} from '../Formy/FormyDateRange';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum, FormyMultiEnum} from '../Formy/FormyEnum';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextNum, FormyTextStr, FormyUnit} from '../Formy/FormyText';
import FormyUserGroupSelector from '../Formy/FormyUserGroupSelector';
import {useApis} from '../apis/ApisContext';
import {savePolicyAndRelatedHarvests} from '../mutate/SavePolicy';
import {State} from '../redux';
import {reportErr} from '../util/err';
import './Policies.css';
import {commitMutations} from './commit';

interface HarvestForm {
  harvest_id: Harvest['harvest_id'] | null;
  insured_percent: Harvest['insured_percent'];
  reference_yield: Harvest['reference_yield'];
  commodity_price: Harvest['commodity_price'];
  premium_rate_percent: Harvest['premium_rate_percent'];
  selectedFieldLevelHarvestIds: Uuid[];
}

class FormyPolicyCustomColumns extends PolicyCustomColumns {
  brokers: Broker[];
  beneficiaries: Beneficiary[];
  mga: MgaPolicyCustomColumns;
  insured: LegalEntity;

  constructor(x: Partial<FormyPolicyCustomColumns> | null) {
    super(x);
    // To make formy interface work, we need to make sure that nested objects (e.g. insured) and arrays are defined.
    this.brokers = x?.brokers ?? [];
    this.beneficiaries = x?.beneficiaries ?? [];
    this.insured = x?.insured ?? {
      external_id: null,
      street_with_number: null,
      country: null,
      zip_code: null,
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      gender: null,
      trade_name: null,
      corporate_name: null,
      inception_date: null,
      insured_status: null,
    };
    this.mga = x?.mga ?? {
      culture_code: null,
      product_code: null,
      years_of_agriculture: null,
      climatic_event_past_5_years: null,
      crop_already_planted: null,
      preexisting_policy_for_same_area: null,
      certified_or_registered_seeds: null,
      drainage_problems: null,
      flood_or_waterlogging_past_5_years: null,
      aware_of_zoagro_mapa: null,
      subsidy_policy_id: null,
    };
  }
}

export class PolicyForm implements Nullable<OmitDbColumns<Policy>, 'policy_id' | 'user_group' | 'policy_number'> {
  // Properties directly from the policy.
  policy_id: Uuid | null;
  user_group: Policy['user_group'] | null;
  policy_number: Policy['policy_number'] | null;
  comments: Policy['comments'];
  custom_columns: FormyPolicyCustomColumns;
  metadata: Policy['metadata'];
  editors: Policy['editors'];
  status: Policy['status'];
  merged_ids: Policy['merged_ids'];

  // Properties to manage the related (insured) farm harvests.
  farm_id: Uuid | null;
  harvest_year: HarvestYear;
  harvest_forms: HarvestForm[];

  constructor(clock: ClockI, policy: Policy | null, harvests: Harvest[]) {
    this.user_group = policy?.user_group ?? null;
    this.policy_number = policy?.policy_number ?? null;
    this.policy_id = policy?.policy_id ?? null;
    this.comments = policy?.comments ?? null;
    this.custom_columns = new FormyPolicyCustomColumns(policy?.custom_columns ?? null);
    this.metadata = policy?.metadata ?? null;
    this.editors = policy?.editors ?? [];
    this.status = policy?.status ?? 'policy';
    this.merged_ids = policy?.merged_ids ?? [];
    this.harvest_forms = harvests
      .filter(harvest => harvest.farm_id)
      .map(farmLevelHarvest => {
        return {
          harvest_id: farmLevelHarvest.harvest_id,
          insured_percent: farmLevelHarvest.insured_percent,
          reference_yield: farmLevelHarvest.reference_yield,
          commodity_price: farmLevelHarvest.commodity_price,
          premium_rate_percent: farmLevelHarvest.premium_rate_percent,
          selectedFieldLevelHarvestIds: harvests
            .filter(harvest => harvest.field_id && harvestAggregationKeyEq(harvest, farmLevelHarvest))
            .map(harvest => harvest.harvest_id),
        };
      });
    this.farm_id =
      harvests
        .map(harvest => harvest.farm_id)
        .filter(remove.nulls)
        .shift() ?? null;
    this.harvest_year = harvests.map(harvest => harvest.harvest_year).shift() ?? getCurYear(clock);
  }
}

const errorStyle: CSSProperties = {display: 'flex', flexDirection: 'column', color: '#C23B22'};
const maxWidthResponsiveHeight = {width: '100%', height: 'inherit'};

const STATUS_COLORS: Partial<Record<RequestStatus, React.CSSProperties>> = {
  new: {
    padding: '5px',
    backgroundColor: '#fff9db',
    border: '2px solid #ffeb3b',
    color: '#fbc02d',
    borderRadius: '5px',
  },
  ok: {
    padding: '5px',
    backgroundColor: '#e0f7e9',
    border: '2px solid #4caf50',
    color: '#388e3c',
    borderRadius: '5px',
  },
  error: {
    padding: '5px',
    backgroundColor: '#fde0e0',
    border: '2px solid #f44336',
    color: '#d32f2f',
    borderRadius: '5px',
  },
};

function validatePolicyForm(x: PolicyForm): FormyErrors<PolicyForm> {
  return {
    user_group: !x.user_group,
    policy_number: !x.policy_number || !isStr(x.policy_number),
    harvest_forms:
      !x.harvest_forms ||
      !isArr(x.harvest_forms) ||
      x.harvest_forms.map(harvestForm => {
        return {
          harvest_id: !harvestForm.harvest_id,
        };
      }),
  };
}

export const AddOrEditPolicy: React.FC = () => {
  const apis = useApis();
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = React.useState<I18nSimpleKey | I18nParametric | null>(null);
  const onSubmit = useCallback(
    async (policyForm: PolicyForm) => {
      await savePolicyAndRelatedHarvests(apis, policyForm);
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    },
    [apis, history],
  );
  const policyId = useMemo(() => new URLSearchParams(location.search).get('policy_id') ?? '', [location]);
  const formy = useFormy(
    policyId ? 'edit' : 'new',
    async () => {
      if (!policyId) {
        return new PolicyForm(apis.clock, null, []);
      }
      const policy = await fetchEntity(apis.authedFetcher, 'policy', policyId);
      const policyHarvests = await fetchEntitiesBy(apis.authedFetcher, 'harvest', getPolicyFilter(policy.policy_id));
      return new PolicyForm(apis.clock, policy, policyHarvests);
    },
    apis.t,
    onSubmit,
    validatePolicyForm,
    policyId,
  );
  return formy ? <AddOrEditPolicyForm formy={formy} error={error} setError={setError} /> : null;
};

const AddOrEditPolicyForm: React.FC<{
  formy: Formy<PolicyForm>;
  error: I18nSimpleKey | I18nParametric | null;
  setError: React.Dispatch<React.SetStateAction<I18nSimpleKey | I18nParametric | null>>;
}> = ({formy, error, setError}) => {
  const apis = useApis();
  const [validations, setValidations] = React.useState<string[]>([]);
  const history = useHistory();
  const policyId = useFormyValue(formy, 'policy_id');
  const farmId = useFormyValue(formy, 'farm_id');
  const harvestYear = useFormyValue(formy, 'harvest_year');
  const harvestForms = useFormyValue(formy, 'harvest_forms');
  const customColumns = formy.getSectionFormy('custom_columns');
  const mgaCustomColumns = customColumns.getSectionFormy('mga');
  const brokers = useFormyValue(customColumns, 'brokers');
  const beneficiaries = useFormyValue(customColumns, 'beneficiaries');
  const subsidy = useAsyncMemo(async () => {
    if (!policyId) {
      return null;
    }
    const result = await apis.authedFetcher({
      method: 'GET',
      path: 'api/request',
      params: getPostgrestQueryParams({
        and: [
          {column: 'request->>policy_id', operator: 'eq', value: policyId},
          {column: 'request_type', operator: 'eq', value: 'subsidy'},
        ],
      }),
    });

    if (!result.length) {
      return null;
    }

    function formatStatus(status: string): string {
      if (status === 'new') {
        return apis.t('Pending');
      } else if (status === 'error') {
        return apis.t('Error');
      } else {
        return apis.t('Submitted');
      }
    }

    return {
      formattedStatus: formatStatus(result[0].status),
      status: result[0].status as RequestStatus,
      response: result[0].errors?.join('\n'),
    };
  }, [apis, policyId]);
  const availableFarmLevelHarvests = useAsyncMemo(async () => {
    if (!farmId || !harvestYear) {
      return [];
    }
    return await fetchEntitiesBy(apis.authedFetcher, 'harvest', {
      and: [
        {
          column: 'farm_id',
          operator: 'eq',
          value: farmId,
        },
        {
          column: 'harvest_year',
          operator: 'eq',
          value: harvestYear,
        },
      ],
    });
  }, [apis, farmId, harvestYear]);
  const availableFields = useAsyncMemo(async () => {
    if (!farmId) {
      return [];
    }
    return await fetchEntitiesBy(apis.authedFetcher, 'field', {
      column: 'farm_id',
      operator: 'eq',
      value: farmId,
    });
  }, [apis, farmId]);
  const availableFieldLevelHarvests = useAsyncMemo(async () => {
    if (!availableFields?.length) {
      return [];
    }
    return await fetchEntitiesBy(apis.authedFetcher, 'harvest', {
      and: [
        {
          column: 'field_id',
          operator: 'in',
          value: availableFields.map(field => field.field_id),
        },
        {
          column: 'harvest_year',
          operator: 'eq',
          value: harvestYear,
        },
      ],
    });
  }, [apis, availableFields, harvestYear]);

  const handleAddHarvestForm = useCallback(() => {
    const currentHarvestForms = formy.getValue('harvest_forms');
    const updatedHarvestForms: HarvestForm[] = [
      ...currentHarvestForms,
      {
        harvest_id: null,
        insured_percent: null,
        reference_yield: null,
        commodity_price: null,
        premium_rate_percent: null,
        selectedFieldLevelHarvestIds: [],
      },
    ];
    formy.getChangeHandler('harvest_forms')(updatedHarvestForms);
  }, [formy]);

  const handleAddBroker = useCallback(() => {
    const updatedBrokers = [
      ...(brokers ?? []),
      {
        entity: {
          trade_name: null,
          corporate_name: null,
          email: null,
          first_name: null,
          last_name: null,
          phone: null,
          external_id: null,
          inception_date: null,
          gender: null,
          street_with_number: null,
          zip_code: null,
          country: null,
          insured_status: null,
        },
        leader: null,
      },
    ];
    customColumns.getChangeHandler('brokers')(updatedBrokers);
  }, [customColumns, brokers]);

  const handleDeleteHarvestForm = useCallback(
    (formIdx: number) => {
      const updatedHarvestForms = formy.getValue('harvest_forms').filter((_, index) => index != formIdx);
      formy.getChangeHandler('harvest_forms')(updatedHarvestForms);
    },
    [formy],
  );
  const handleDeleteBroker = useCallback(
    (formIdx: number) => {
      const currentBrokers = (brokers ?? []).filter((_, index) => index != formIdx);
      customColumns.getChangeHandler('brokers')(currentBrokers);
    },
    [customColumns, brokers],
  );

  const handleAddBeneficiary = useCallback(() => {
    const updatedBeneficiaries = [
      ...(beneficiaries ?? []),
      {
        entity: {
          trade_name: null,
          corporate_name: null,
          email: null,
          first_name: null,
          last_name: null,
          phone: null,
          external_id: null,
          inception_date: null,
          gender: null,
          street_with_number: null,
          zip_code: null,
          country: null,
          insured_status: null,
        },
        percent: null,
      },
    ];
    customColumns.getChangeHandler('beneficiaries')(updatedBeneficiaries);
  }, [customColumns, beneficiaries]);

  const handleDeleteBeneficiary = useCallback(
    (formIdx: number) => {
      const currentBrokers = (beneficiaries ?? []).filter((_, index) => index != formIdx);
      customColumns.getChangeHandler('beneficiaries')(currentBrokers);
    },
    [customColumns, beneficiaries],
  );

  formy.watchValue('farm_id', () => {
    formy.getChangeHandler('harvest_forms')([]);
  });
  formy.watchValue('user_group', () => {
    formy.getChangeHandler('farm_id')(null);
  });

  const handleDeletePolicyAction = useCallback(async () => {
    if (policyId) {
      const claims = await fetchEntitiesBy(apis.authedFetcher, 'claim', {
        column: 'policy_id',
        operator: 'eq',
        value: policyId,
      });
      if (claims.length) {
        setError({
          type: 'NoDeletePolicy',
          claimCount: claims.length,
        });
        return;
      }
      apis.store.dispatch(queueDeleteMutation('policy', policyId));
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    }
  }, [apis, policyId, history, setError]);

  const handleUpdatePolicyStatus = useCallback(
    async (policyStatus: PolicyStatus) => {
      if (!policyId) {
        throw new Error('Policy ID is missing');
      }
      apis.store.dispatch(queueUpdateMutation('policy', {status: policyStatus}, policyId));
      if (await commitMutations(apis)) {
        location.reload();
      } else {
        setError('FailedToSyncChanges');
      }
    },
    [apis, policyId, setError],
  );

  const handleSaveAndApprovePolicy = useCallback(async () => {
    if (!policyId) {
      throw new Error('Policy ID is missing');
    }

    // Save policy first so the up-to-date policy entity is available on the server for validation.
    // I opted for this approach, because the validation code will also run before sending the policy
    // to external APIs (federal subsidy, AXA) and this simplifies the validation logic
    // (e.g. read the policy entity from database and run the validations). Let me know if you have any second thoughts,
    // and I can rewrite the code to remove saving the policy and send the latest values as part of validate/proposal request).
    await savePolicyAndRelatedHarvests(apis, formy.getValues());
    if (!(await commitMutations(apis))) {
      setError('FailedToSyncChanges');
    }

    const response = await apis.authedFetcher({
      method: 'GET',
      path: 'api2/validate/proposal',
      params: [['policy_id', policyId]],
    });

    if (response.validation) {
      await handleUpdatePolicyStatus('quote_accepted');
    } else if (response.errors) {
      setValidations(response.errors);
    }
  }, [apis, formy, handleUpdatePolicyStatus, policyId, setError]);

  const insuredCustomColumns = customColumns.getSectionFormy('insured');
  return (
    <div className={'policies-form'}>
      <PageHeader
        title={policyId ? apis.t('EditPolicy') : apis.t('AddPolicy')}
        className="no-print"
        avatar={{icon: <FormOutlined />}}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Portfolio')}:</Label>
          <FormyUserGroupSelector formy={formy} field={'user_group'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Status')}:</Label>
          <FormyEnum
            formy={formy}
            field={'status'}
            options={Array.from(PolicyStatus).map(status => [status, apis.t(status)])}
            selectMsg={null}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('PolicyNumber')}:</Label>
          <FormyTextStr formy={formy} field={'policy_number'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Comments')}:</Label>
          <FormyTextStr formy={formy} field={'comments'} label={'PleaseEnterValue'} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Farm')}:</Label>
          <FormyEntitySelector field="farm_id" formy={formy} entityType="farm" onNewEntity={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('HarvestYear')}:</Label>
          <FormyEnum
            formy={formy}
            field={'harvest_year'}
            selectMsg={'Select'}
            options={HarvestYear.map(harvestYear => {
              return [harvestYear, apis.t(harvestYear)];
            })}
          />
        </Col>
      </Row>

      {farmId && (
        <Row gutter={16}>
          <h3>{apis.t('InsuredHarvests')}</h3>
        </Row>
      )}
      {Object.values(harvestForms).map((harvestForm, harvestFormIndex) => {
        const sectionFormy = formy.getSectionFormy('harvest_forms').getSectionFormy(harvestFormIndex);
        return (
          <HarvestRow
            key={harvestFormIndex + '-' + harvestForm.harvest_id}
            formy={sectionFormy}
            deleteHarvestForm={() => handleDeleteHarvestForm(harvestFormIndex)}
            farmLevelHarvests={availableFarmLevelHarvests}
            fieldLevelHarvests={availableFieldLevelHarvests}
            fields={availableFields}
          />
        );
      })}
      {farmId && (
        <Row gutter={16}>
          <Col className={'row-item-margin-top'}>
            <Button type="default" icon={<PlusOutlined />} onClick={handleAddHarvestForm}>
              {apis.t('InsureAnotherHarvest')}
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <h3>{apis.t('AdditionalInfo')}</h3>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Label>{apis.t('Validity')}:</Label>
          <FormyDateRange formy={customColumns} range_from_field={'valid_from'} range_to_field={'valid_to'} />
        </Col>
      </Row>

      <h3>{apis.t('Insured')}</h3>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('FirstName')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'first_name'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('LastName')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'last_name'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('DocumentNumber')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'external_id'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('BirthDate')}:</Label>
          <FormyDatePicker formy={insuredCustomColumns} field={'inception_date'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Email')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'email'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Phone')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'phone'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Address')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'street_with_number'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('ZipCode')}:</Label>
          <FormyTextStr formy={insuredCustomColumns} field={'zip_code'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Gender')}:</Label>
          <FormyEnum
            formy={insuredCustomColumns}
            field={'gender'}
            selectMsg={null}
            options={Gender.map(gender => [gender, apis.t(gender)])}
          />
        </Col>
      </Row>

      <Divider />

      <Row gutter={16}>
        <h3>{apis.t('AdditionalQuestions')}</h3>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CropAlreadyPlanted?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'crop_already_planted'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('PreexistingPolicy?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'preexisting_policy_for_same_area'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CertifiedOrRegisteredSeeds?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'certified_or_registered_seeds'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('DrainageProblems?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'drainage_problems'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('FloodOrWaterloggingPast5Years?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'flood_or_waterlogging_past_5_years'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('AwareOfZoagroMapa?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'aware_of_zoagro_mapa'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('ClimaticEventPast5Years?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'climatic_event_past_5_years'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('YearsOfAgriculture?')}:</Label>
          <FormyTextNum formy={mgaCustomColumns} field={'years_of_agriculture'} min={0} max={100} />
        </Col>
      </Row>

      {subsidy && (
        <>
          <h3>{apis.t('Subsidy')}</h3>
          <Row gutter={16}>
            <Col span={4}>
              <Label>{apis.t('Status')}:</Label>
              <div style={STATUS_COLORS[subsidy.status]}>{subsidy.formattedStatus}</div>
            </Col>
            {subsidy.response && (
              <Col span={12}>
                <Label>{apis.t('Message')}:</Label>
                <div>{subsidy.response}</div>
              </Col>
            )}
          </Row>
        </>
      )}

      <h3>{apis.t('Brokers')}</h3>

      {Object.values(brokers ?? []).map((_, index) => {
        const brokerSection = customColumns.getSectionFormy('brokers');
        return (
          <BrokerRow
            key={'broker-' + index}
            formy={brokerSection.getSectionFormy(index)}
            deleteBroker={() => handleDeleteBroker(index)}
          />
        );
      })}
      <Row gutter={16}>
        <Col className={'row-item-margin-top'}>
          <Button type="default" icon={<PlusOutlined />} onClick={handleAddBroker}>
            {apis.t('AddBroker')}
          </Button>
        </Col>
      </Row>

      <h3>{apis.t('Beneficiaries')}</h3>
      {Object.values(beneficiaries ?? []).map((_, index) => {
        const beneficiariesSection = customColumns.getSectionFormy('beneficiaries');
        return (
          <BeneficiaryRow
            key={'beneficiary-' + index}
            formy={beneficiariesSection.getSectionFormy(index)}
            deleteBeneficiary={() => handleDeleteBeneficiary(index)}
          />
        );
      })}
      <Row gutter={16}>
        <Col className={'row-item-margin-top'}>
          <Button type="default" icon={<PlusOutlined />} onClick={handleAddBeneficiary}>
            {apis.t('AddBeneficiary')}
          </Button>
        </Col>
      </Row>

      {validations.length > 0 && (
        <div style={errorStyle}>
          {validations.map((validation, idx) => (
            <div key={`error-${idx}`}>{validation}</div>
          ))}
        </div>
      )}

      <div className="add-farms-buttons">
        <Button type="default" icon={<CloseOutlined />} onClick={() => history.goBack()}>
          {apis.t('Back')}
        </Button>
        <FormySubmit
          type="primary"
          icon={<SaveOutlined />}
          formy={formy}
          label="Save"
          doNotConfirmSubmissionToUser={true}
        />
        {policyId && (
          <>
            <Button icon={<CheckOutlined />} onClick={() => handleSaveAndApprovePolicy()}>
              {apis.t('SaveApprove')}
            </Button>
            <Button icon={<CloseOutlined />} onClick={() => handleUpdatePolicyStatus('quote_rejected')}>
              {apis.t('Reject')}
            </Button>
            <Button icon={<DeleteOutlined />} onClick={handleDeletePolicyAction}>
              {apis.t('Delete')}
            </Button>
          </>
        )}
      </div>
      <div style={errorStyle}>{error && apis.t(error)}</div>
    </div>
  );
};

interface HarvestRowProps {
  formy: FormyI<HarvestForm>;
  deleteHarvestForm: () => void;
  farmLevelHarvests?: Harvest[];
  // HarvestRow assumes that there is a field in the fields prop for each fieldLevelHarvest in fieldLevelHarvests prop.
  fieldLevelHarvests?: Harvest[];
  fields?: Field[];
}

const harvestFormStyle = (borderColor: string) => {
  return {
    borderLeft: `5px solid ${borderColor}`,
    marginTop: '0.5rem',
    borderRadius: '0.25rem',
  };
};
const marginLeft20 = {marginLeft: '20px'};

const HarvestRow: React.FC<HarvestRowProps> = ({
  formy,
  farmLevelHarvests,
  deleteHarvestForm,
  fieldLevelHarvests,
  fields,
}) => {
  const {t} = useApis();
  const allCrops: IndexedCrops = useSelector((state: State) => state.crops.crops);
  const countryGroups: string[] = useSelector(getCountryCodeGroups);
  const harvest_id = useFormyValue(formy, 'harvest_id');
  const harvest = useMemo(
    () => farmLevelHarvests?.find(harvest => harvest.harvest_id === harvest_id),
    [harvest_id, farmLevelHarvests],
  );
  const borderColor = CROP_COLORS[getBaseCrop(allCrops, harvest?.crop_id)!] ?? 'transparent';
  const commodityPrice = useFormyValue(formy, 'commodity_price');
  const insuredPercent = useFormyValue(formy, 'insured_percent');
  const referenceYield = useFormyValue(formy, 'reference_yield');
  const premiumRatePercent = useFormyValue(formy, 'premium_rate_percent');
  const selectedFieldLevelHarvestIds = useFormyValue(formy, 'selectedFieldLevelHarvestIds');
  // Note that this fields harvests area calculation and the following policy calculations will only work,
  // if field-level harvests already exists for the selected farm (and can thus be selected on the user interface and stored
  // in formy's selectedFieldLevelHarvestIds). Currently, the feature is only for MGA, for which the field-level
  // harvests should already exist. Re-discuss this when the feature is open for other customers.
  const selectedFieldsHarvestAreaHa = useMemo(
    () =>
      selectedFieldLevelHarvestIds
        .map(id => {
          const {harvest_area, field_id} = fieldLevelHarvests?.find(h => h.harvest_id === id) ?? {};
          return harvest_area ?? fields?.find(f => f.field_id === field_id)?.field_area ?? null;
        })
        .map(area => convertArea('hectares', area)?.val ?? null)
        .reduce(...aggregate.sum) ?? 0,
    [selectedFieldLevelHarvestIds, fields, fieldLevelHarvests],
  );
  const monetaryUnit = convertToMonetaryUnit(commodityPrice?.unit);
  const insuredYield = (referenceYield?.val ?? 0) * ((insuredPercent ?? 0) / 100);
  const coveragePerArea = insuredYield * (commodityPrice?.val ?? 0);
  const sumInsured = coveragePerArea * selectedFieldsHarvestAreaHa;
  const premium = sumInsured * ((premiumRatePercent ?? 0) / 100);
  const fieldLevelHarvestOptions = useMemo((): [string, string][] => {
    return (fieldLevelHarvests ?? [])
      .filter(fieldLevelHarvest => harvestAggregationKeyEq(fieldLevelHarvest, harvest))
      .map(fieldLevelHarvest => {
        const field = fields?.find(field => field.field_id === fieldLevelHarvest.field_id);
        if (!field) {
          reportErr(
            `Field level harvest (${fieldLevelHarvest.harvest_id}) has no matching field!`,
            'AddOrEditPolicy-HarvestRow',
          );
          return null;
        }
        return [
          fieldLevelHarvest.harvest_id,
          fieldDesc(t, allCrops, [], field, fieldLevelHarvest, null, null, false, true, true),
        ];
      })
      .filter((option): option is [string, string] => option !== null);
  }, [fieldLevelHarvests, fields, harvest, allCrops, t]);
  return (
    <div style={harvestFormStyle(borderColor)}>
      <Row gutter={16}>
        <Col span={12}>
          <Label>{t('InsuredHarvest')}:</Label>
          <FormyEnum
            required
            formy={formy}
            field={'harvest_id'}
            options={
              farmLevelHarvests?.map(harvest => {
                return [harvest.harvest_id, harvestDesc(t, allCrops, harvest, null, countryGroups, null, true)];
              }) ?? []
            }
            selectMsg={'Select'}
          />
        </Col>
        <Col span={2}>
          <Button
            className="row-item-margin-top"
            icon={<DeleteOutlined />}
            onClick={deleteHarvestForm}
            size="small"
            danger
            type="ghost"
          />
        </Col>
      </Row>
      <Row>
        <Col span={8} style={marginLeft20}>
          <Label>{t('InsuredFields')}:</Label>
          <FormyMultiEnum
            style={maxWidthResponsiveHeight}
            formy={formy}
            maxTagCount={20}
            field={'selectedFieldLevelHarvestIds'}
            selectMsg={'Select'}
            options={fieldLevelHarvestOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col span={4} style={marginLeft20}>
          <Label>{t('CommodityPrice')}:</Label>
          <FormyUnit inline={true} formy={formy} units={UnitPriceUnit} field={'commodity_price'} />
        </Col>
        <Col span={4}>
          <Label>{t('InsuredPercent')}:</Label>
          <FormyTextNum formy={formy} field={'insured_percent'} min={0} max={100} />
        </Col>
      </Row>
      <Row>
        <Col span={4} style={marginLeft20}>
          <Label>{t('ReferenceYield')}:</Label>
          <FormyUnit units={YieldUnit} inline={true} formy={formy} field={'reference_yield'} />
        </Col>
        <Col span={4}>
          <Label>{t('PremiumRatePercent')}:</Label>
          <FormyTextNum formy={formy} field={'premium_rate_percent'} min={0} max={100} />
        </Col>
      </Row>
      <Row>
        <Col span={3} style={marginLeft20}>
          <Label>{t('CoveragePerArea')}:</Label>
          <div>
            {coveragePerArea.toFixed(2)} {commodityPrice?.unit ? t(commodityPrice.unit as I18nSimpleKey) : null}
          </div>
        </Col>
        <Col span={3}>
          <Label>{t('SumInsured')}:</Label>
          <div>
            {sumInsured.toFixed(2)} {monetaryUnit ? t(monetaryUnit as I18nSimpleKey) : null}
          </div>
        </Col>
        <Col span={3}>
          <Label>{t('Premium')}:</Label>
          <div>
            {premium.toFixed(2)} {monetaryUnit ? t(monetaryUnit as I18nSimpleKey) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

interface BrokerRowProps {
  formy: FormyI<Broker>;
  deleteBroker: () => void;
}

const BrokerRow: React.FC<BrokerRowProps> = ({formy, deleteBroker}) => {
  const {t} = useApis();
  const entity = formy.getSectionFormy('entity');
  return (
    <div style={harvestFormStyle('gray')}>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('FirstName')}:</Label>
          <FormyTextStr formy={entity} field={'first_name'} />
        </Col>
        <Col span={6}>
          <Label>{t('LastName')}:</Label>
          <FormyTextStr formy={entity} field={'last_name'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Email')}:</Label>
          <FormyTextStr formy={entity} field={'email'} />
        </Col>
        <Col span={6}>
          <Label>{t('Phone')}:</Label>
          <FormyTextStr formy={entity} field={'phone'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Address')}:</Label>
          <FormyTextStr formy={entity} field={'street_with_number'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{t('ZipCode')}:</Label>
          <FormyTextStr formy={entity} field={'zip_code'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('DocumentNumber')}:</Label>
          <FormyTextStr formy={entity} field={'external_id'} />
        </Col>
        <Col span={6}>
          <Label>{t('IsBrokerLeader?')}:</Label>
          <FormyBool formy={formy} field={'leader'} selectMsg={null} />
        </Col>
        <Col span={2}>
          <Button
            className="row-item-margin-top"
            icon={<DeleteOutlined />}
            onClick={deleteBroker}
            size="small"
            danger
            type="ghost"
          />
        </Col>
      </Row>
    </div>
  );
};

interface BeneficiaryRowProps {
  formy: FormyI<Beneficiary>;
  deleteBeneficiary: () => void;
}

const BeneficiaryRow: React.FC<BeneficiaryRowProps> = ({formy, deleteBeneficiary}) => {
  const {t} = useApis();
  const entity = formy.getSectionFormy('entity');
  return (
    <div style={harvestFormStyle('gray')}>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Name')}:</Label>
          <FormyTextStr formy={entity} field={'trade_name'} />
        </Col>
        <Col span={6}>
          <Label>{t('DocumentNumber')}:</Label>
          <FormyTextStr formy={entity} field={'external_id'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Email')}:</Label>
          <FormyTextStr formy={entity} field={'email'} />
        </Col>
        <Col span={6}>
          <Label>{t('Phone')}:</Label>
          <FormyTextStr formy={entity} field={'phone'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Address')}:</Label>
          <FormyTextStr formy={entity} field={'street_with_number'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{t('ZipCode')}:</Label>
          <FormyTextStr formy={entity} field={'zip_code'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Percent')}:</Label>
          <FormyTextNum formy={formy} field={'percent'} min={0} max={99} />
        </Col>
        <Col span={2}>
          <Button
            className="row-item-margin-top"
            icon={<DeleteOutlined />}
            onClick={deleteBeneficiary}
            size="small"
            danger
            type="ghost"
          />
        </Col>
      </Row>
    </div>
  );
};
