// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentina',
  'AUS': 'Australia',
  'AUT': 'Austria',
  'Accept': 'Aceptar',
  'AcceptOrDecline': 'Aceptar/Rechazar',
  'AddAnotherArea': 'Agregar una zona',
  'AddAssignee': 'Agregar firmante',
  'AddAttachments': 'agregar archivos adjuntos',
  'AddBeneficiary': 'Agregar beneficiario',
  'AddBroker': 'Agregar corredor',
  'AddCause': 'Añadir una causa',
  'AddClaim': 'Agregar reclamo',
  'AddCost': 'Adicionar costo',
  'AddCrop': 'Adicionar tipo de cultivo',
  'AddEditSampleShape': 'Dibuje el área afectada en el mapa',
  'AddEditor': 'Agregar editor',
  'AddEditors': 'Agregar editores',
  'AddEditorsOneGroup': 'Por favor agregue editores para los items en solo un portafolio',
  'AddFarm': 'Agregar un establecimiento',
  'AddFarmInfo': 'Observe que, para adicionar lotes nuevos/adicionales a um campo EXISTENTE, primero es necesario buscar el nombre del campo o referencia del campo en los lotes correspondientes abajo.',
  'AddField': 'Agregar un lote',
  'AddFieldShape': 'Agregar perímetro del lote',
  'AddFieldShapes': 'Agregar perímetros del lote',
  'AddFieldsHarvests': 'Agregar lotes y campaña',
  'AddFromFileManager': 'Agregar desde el Administrador de archivos',
  'AddFromPhotos': 'Agregar desde fotos',
  'AddGuaranteedLossCause': 'Agregar una causa de pérdida cubierta',
  'AddHarvest': 'Agregar una campaña',
  'AddLossCause': 'Agregar el motivo de la pérdida',
  'AddNewDamage': 'Añadir nuevo daño',
  'AddNewHarvest': 'Crear una campaña',
  'AddNewHarvestFor': 'Crear una nueva cosecha para',
  'AddNewLoss': 'Añadir nueva pérdida',
  'AddNewSubparcel': 'Agregar nueva subparcela',
  'AddOrEditFields': 'Agregar/editar lotes',
  'AddPhoto': 'Agregar una foto',
  'AddPhotoError': 'Problema con la toma de la foto',
  'AddPolicy': 'Agregar una póliza',
  'AddRecipient': 'Agregar destinatario',
  'AddSample': 'Agregar una muestra',
  'AddSamplePhoto': 'Agregar una foto a la muestra',
  'AddSubplotId': 'Agregar ID de subtrama',
  'AddUnguaranteedLossCause': 'Agregar una causa de pérdida no cubierta',
  'AddUser': 'Agregar usuario',
  'AddVarietyHarvest': 'Añade variedad',
  'AddedBy': 'Añadido por',
  'AddedGroups': 'Grupos agregados',
  'AddedOn': 'Añadido en',
  'AdditionalInfo': 'Información adicional',
  'AdditionalQuestions': 'Preguntas adicionales',
  'Address': 'DIRECCIÓN',
  'AdvancedOptions': 'Opciones avanzadas',
  'AffectedArea': 'Área afectada',
  'AffectedCrop': 'Cultivo afectado',
  'AffectedCrops': 'Cultivos afectados',
  'AffectedCropsRequiredToDeclareLoss': 'Debes seleccionar al menos un cultivo afectado para poder sumar las pérdidas declaradas.',
  'AffectedField': 'Lote afectado',
  'All': 'Todo',
  'AllSynced': 'Todo sincronizado',
  'AllTime': 'Todo el tiempo',
  'AlsoInheritedFrom': 'También heredado de',
  'Amount': 'Valor',
  'AppVersionDeprecated': 'Versión de la aplicación obsoleta',
  'AppVersionDeprecatedMessage': 'La versión de su aplicación está en desuso. Debe actualizar la página para obtener la nueva versión. Haga clic en ACTUALIZAR para actualizar la página. Si tiene datos no guardados, haga clic en cancelar y haga una copia de seguridad manual de los datos y luego actualice la página.',
  'Area': 'Superficie',
  'AreaCropTitle': 'Área por cultivo',
  'Ascending': 'Ascendiente',
  'Assign': 'Firmar y Finalizar',
  'AssignExistingFields': 'Asignar lotes existentes',
  'AssignedLossAdjusters': 'Ajustadores de pérdidas asignados',
  'AssignedTo': 'Asignado a',
  'AssociatedEmails': 'E-mails relacionados',
  'Attachment': 'Adjunto',
  'Attachments': 'Anexos',
  'AuxKey': 'Tecla auxiliar',
  'Average': 'Promedio',
  'AverageFieldArea': 'Superficie media del lote',
  'AvgBollsPerPlant': 'Número medio de capullos por planta',
  'AvgEarsPerPlant': 'Número medio de espigas por planta',
  'AvgGrainsPerEar': 'Número medio de granos por espiga',
  'AvgGrainsPerPod': 'Número medio de granos por vaina',
  'AvgGrainsPerSilique': 'Número medio de granos por vaina',
  'AvgKernelsPerBoll': 'Número medio de granos por capullo',
  'AvgPodsPerShaft': 'Número medio de vainas por ramificación',
  'AvgSiliquesPerShaft': 'Número medio de vainas por ramificación',
  'AwareOfZoagroMapa?': '¿Conoces Zoagro MAPA?',
  'BEL': 'Bélgica',
  'BRA': 'Brasil',
  'Back': 'Regresar',
  'BackToHome': 'Volver a la página principal',
  'Benchmark': 'Referencia',
  'Beneficiaries': 'Beneficiarios',
  'Biomass': 'Biomasa',
  'BirthDate': 'Fecha de nacimiento',
  'Brokers': 'Corredores',
  'BunchWeight': 'Peso promedio por racimo de uvas (gramos)',
  'BunchesPerPlant': 'Número promedio de racimos de uvas por planta',
  'ByCrop': 'Por cultivo',
  'ByField': 'Por lote',
  'ByFieldArea': 'Por superficie de lote',
  'ByFieldCount': 'Por número de lotes',
  'CAN': 'Canadá',
  'CHE': 'Suiza',
  'CZE': 'Chequia',
  'Calculate': 'Calcular',
  'CalculateAbbreviated': 'Calc.',
  'Cancel': 'Cancelar',
  'CantSelectTooManyItems': 'No puedes seleccionar más de 50 elementos.',
  'CertifiedOrRegisteredSeeds?': '¿Las semillas están certificadas o registradas?',
  'Change': 'Editar',
  'ChangeOverrideSampleMsg': '¿Está seguro de que desea cambiar el valor de anulación para este cultivo?',
  'ChangeUserAccessRights': 'Cambiar los derechos de acceso de los usuarios',
  'CheckForCodepushUpdate': 'Buscar actualizaciones',
  'CirclesArea': 'Área del círculo',
  'CirclesDiameter': 'Diámetro del círculo',
  'CirclesRadius': 'Radio del círculo',
  'City': 'Ciudad',
  'Claim': 'Afirmar',
  'ClaimAssign': 'Ceder Reclamo',
  'ClaimNumber': 'Número de siniestro',
  'ClaimProcessingStartEnd': 'Inicio y fin del procesamiento de reclamos',
  'ClaimRequests': 'Solicitudes de reclamación',
  'ClaimStatus_adjuster-accepted': 'Ajustador aceptado',
  'ClaimStatus_assessment-in-progress': 'Evaluación en curso',
  'ClaimStatus_awaiting-adjuster-acceptance': 'A la espera de la aceptación del ajustador',
  'ClaimStatus_completed': 'Terminado',
  'ClaimStatus_created-unassigned': 'Creado y no asignado',
  'ClaimStatus_payment-proposal-sent': 'Propuesta de pago enviada',
  'ClaimStatus_rejected': 'Rechazado',
  'ClaimStatus_visit-date-defined': 'Fecha de visita definida',
  'ClaimStatus_visit-report-created': 'Informe de visita creado',
  'ClaimStatus_visit-samples-collected': 'Visita iniciada',
  'Claims': 'Reclamos',
  'Clear': 'Limpiar',
  'ClearAll': 'Borrar todo',
  'Clearing': 'Cancelación',
  'ClickHereToAddFarmerEmail': 'Presione aqui para agregar el e-mail del agricultor',
  'ClickUpload': 'Transmitir',
  'ClimaticEventPast5Years?': '¿Hubo algún evento climático en los últimos 5 años?',
  'Close': 'Cerca',
  'CloseVisit': 'Terminar inspección',
  'Closed': 'Terminada',
  'ClosedVisits': 'Inspecciones Finalizadas',
  'CloudCover': 'Cobertura de nubes',
  'CloudCoverInfo': 'Esta columna muestra en destaque si algun año ha sido muy nublado para una medición de Productividad confiable (> 50% de las semanas cubiertas durante el período de máximo desarrollo)',
  'CloudCoverWarning': 'Alerta de cobertura de nubes',
  'CommentInfoWarning': 'Aviso! No aparece en los informes.',
  'Comments': 'Comentarios',
  'CommodityPrice': 'Precio de los productos básicos',
  'CompassHeading': 'Encabezado',
  'CompensableLosses': 'Pérdidas indemnizables',
  'Condition': 'Condición',
  'Configuration': 'Configuración',
  'Confirm': 'Confirmar',
  'Contact': 'Contáctenos',
  'ContainsCopernicus': 'Contiene datos del Servicio Copernicus (2017)',
  'ContinueWithErrorsAndWarnings': 'Continuar sin corregir errores ni avisos?',
  'ConversionRatio': 'Tasa de conversión',
  'CopyCurrentHarvest': 'Copiar campaña actual',
  'CopyHarvestFromPreviousYears': 'Copiar campaña de años anteriores',
  'CopyLink': 'Copiar enlace',
  'CopySample': 'Copiar muestra',
  'CopyVisit': 'Copiar Inspección',
  'CostType': 'Tipo de costo',
  'Costs': 'Costos',
  'CouldntDelete': 'No se puede eliminar este elemento. Debes estar en línea para eliminar.',
  'CoveragePerArea': 'Cobertura por área',
  'CreateNewVisit': 'Crear nueva visita',
  'CropAlreadyPlanted': 'Cultivo ya plantado',
  'CropAlreadyPlanted?': '¿Ya está plantado el cultivo?',
  'CropCondition': 'Condiciones del cultivo',
  'CropConditionBreakdown': 'Estado de los cultivos',
  'CropCoverage': 'Cobertura de cultivo',
  'CropCoverageInfo': 'Esto representa el porcentaje del lote que se encuentra cubierto por el cultivo de interés.',
  'CropType-s': 'Tipo(s) de Cultivo',
  'CropVariety': 'Variedad',
  'CropYieldAverageTitle': 'Rendimiento medio',
  'CurHarvest': 'Cosecha actual',
  'CurrentFilters': 'Filtros actuales',
  'CurrentVisit': 'Inspección Actual',
  'CurrentVisits': 'Inspecciones Actuales',
  'Customer': 'Cliente',
  'DEU': 'Alemania',
  'DamagedFields': 'Campos dañados',
  'DamagedHarvest': 'Cosecha dañada',
  'Damages': 'Daños y perjuicios',
  'Dashboard': 'Panel de control',
  'Date': 'Fecha',
  'Date-s': 'Fecha(s)',
  'DateRange': 'Rango de fechas',
  'DeadPlantsPct': 'Plantas muertas (%)',
  'DeadVinesPct': 'Vides muertas (%)',
  'DeclaredHazard': 'Evento declarado',
  'DeclaredLossCauses': 'Causas de pérdidas declaradas',
  'Decline': 'Rechazar',
  'Deductible': 'Deducible',
  'Deductible-s': 'Franquicia(s)',
  'Delete': 'Borrar',
  'DeleteForEveryone': 'Eliminar para todos',
  'DeleteOfflineArea': 'Eliminar área sin conexión',
  'DeleteOverrideSampleMsg': '¿Está seguro de que desea eliminar la estimación anulada? En caso afirmativo, recurriremos a las muestras para derivar el valor estimado agregado a nivel de cultivo.',
  'DeleteUser': 'Borrar usuario',
  'DeleteUsers': 'Eliminar usuarios',
  'Delimiter': 'Delimitador',
  'Descending': 'Descendiente',
  'DetachedSample-s': 'Muestra(s) separada(s)',
  'Details': 'Detalles',
  'DetectDensity': 'Detectar densidad',
  'DisablePoiMode': 'Mostrar todos los campos',
  'DocumentNumber': 'Número de documento',
  'Done': 'Hecho',
  'Download': 'Descargar',
  'DownloadFailed': 'Descarga fallida',
  'DownloadGeojson': 'Descargar archivo GeoJSON',
  'DownloadSuccess': 'Descargado exitosamente',
  'DownloadXLSX': 'Descargar archivo de Excel',
  'Downloading': 'Descargando',
  'DownloadingInitialData': 'Descargando datos iniciales',
  'DrainageProblems?': '¿Hay algún problema de drenaje?',
  'Draw': 'Dibujar',
  'DuplicateEntryWasRemoved': 'La siguiente entrada ya existe y no será importada:',
  'ESP': 'España',
  'EarDensity': 'Densidad de espigas',
  'Edit': 'Editar',
  'EditClaim': 'Editar reclamo',
  'EditFarm': 'Modificar un establecimiento',
  'EditField': 'Editar un lote',
  'EditFieldShape': 'Editar el perímetro del lote',
  'EditHarvest': 'Cambio de cultivo',
  'EditPolicy': 'Editar la póliza',
  'EditSample': 'Editar muestra',
  'Editors': 'Editores',
  'Email': 'Correo electrónico',
  'EmailMissing': 'Por favor, agregue su correo electrónico!',
  'Emergence': 'Emergencia',
  'EmergenceDate': 'Fecha de emergencia',
  'EnableNow': 'Activar ahora',
  'EnablePoiMode': 'Mostrar solo los campos de interés',
  'EnablePreciseLocation': 'Por favor, habilite la ubicación precisa',
  'EndDate': 'Fecha final',
  'EndDateTime': 'Fecha y hora de finalización',
  'Entity': 'Entidad',
  'ErrMissingFields': 'Por favor, complete los campos que faltan.',
  'Error': 'Error',
  'EstimatedHarvestDate': 'Fecha estimada de cosecha',
  'EstimatedLossAbbr': 'Pérdida est.',
  'EstimatedYield': 'Rendimiento estimado',
  'EstimatedYieldAbbr': 'Rendimiento. est.',
  'EstimatedYieldAreaWeighted': 'Rendimiento estimado (ponderado por área)',
  'EstimatedYieldArithmeticMean': 'Rendimiento estimado (media aritmética)',
  'EstimatedYieldLoss': 'Pérdida de rendimiento estimada',
  'Estimation': 'Estimación',
  'EventDate': 'Fecha del evento',
  'EventValidation': 'Validación de eventos',
  'Existing': 'Existente',
  'ExistingClaim': 'Reclamación existente',
  'ExistingClaimMsg': 'Existe un reclamo existente para esta granja. ¿Le gustaría continuar con el reclamo existente?',
  'ExistingFarm': 'Esta establecimiento ya existe.',
  'ExoBinUnsupported': 'Actualmente, esta función no es compatible con esta versión de la aplicación. Actualice la aplicación a la última versión.',
  'ExoCamera': 'Cámara exótica',
  'ExoDetectionError': 'No se pueden detectar oídos. Vuelva a intentarlo ajustando la posición o capturando una nueva imagen.',
  'ExoModelNotDownloaded': 'Modelo exo no descargado. Conéctese a Internet para descargar el modelo y utilizarlo sin conexión.',
  'ExoNotEnabledForUser': 'Esta función no está habilitada para su empresa o grupo de usuarios. Por favor contacte a su administrador.',
  'ExoTutorialPanDescription': 'Mueva la imagen con un dedo.',
  'ExoTutorialPinchDescription': 'Pellizque la imagen para acercar y alejar.',
  'ExoTutorialSearchDescription': 'Alinee la zona de detección con la parte deseada de la imagen, hasta que se vuelva verde.',
  'Expert': 'Experto',
  'Expert-s': 'Experto(s)',
  'ExpertName': 'Nombre del Perito',
  'ExpertRanking': 'Ranking de expertos',
  'ExpertReport': 'Informe de expertos',
  'ExternalClaimId': 'ID de reclamo externo',
  'ExternalCode': 'Código externo',
  'ExternalFieldId': 'ID de campo externo',
  'ExtremePrecipitation': 'Precipitaciones extremas (>100 mm/semana)',
  'ExtremelyHighTemperature': 'Ola de calor extrema (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Riesgo extremo de heladas (-15°C>Tmin)',
  'FRA': 'Francia',
  'FailedToSyncChanges': 'No se pudieron sincronizar los cambios',
  'Farm': 'Establecimiento',
  'FarmAddress': 'Dirección del establecimiento',
  'FarmAndHarvest': 'Información del establecimiento y la cosecha',
  'FarmDeletedPleaseStartOver': 'Campo fue eliminado! Favor comenzar de nuevo',
  'FarmFormComment': 'Informaciones operacionales adicionales',
  'FarmHarvestLevel': 'Campaña-Nivel',
  'FarmHarvestLevelObservation': 'Observación de la campaña',
  'FarmHarvests': 'Establecimiento Campañas',
  'FarmInfo': 'Información del establecimiento',
  'FarmLocationPrompt': 'Presione firmemente en el mapa para ubicar el establecimiento o presione el botón azul a la izquierda para usar su ubicación actual',
  'FarmName': 'Nombre del establecimiento',
  'FarmReference': 'Referencia de granja',
  'Farmer': 'Productor',
  'FarmerName': 'Nombre del Productor',
  'FarmerOrRepresentative': 'Agricultor o representante',
  'FarmerRepresentative': 'Representante de los agricultores',
  'FarmersEstimatedYieldLoss': 'Pérdida de rendimiento estimada del agricultor',
  'Farms': 'Establecimientos',
  'FeasibleYield': 'Rendimiento realizable',
  'FeasibleYieldEmpty': 'El rendimiento factible está vacío.',
  'Feedback': 'Comentario',
  'FetchReportOfflineInfo': 'Parece que no está conectado, por lo que no se puede recuperar el informe.',
  'Field': 'Lote',
  'Field-s': 'Lote(s)',
  'FieldArea': 'Área del lote',
  'FieldAreaAndReference': 'Superficie del lote y referencia',
  'FieldCultivatedArea': 'Área cultivada (campo)',
  'FieldFormComment': 'Informaciones adicionales del lote',
  'FieldHarvests': 'Año de Cosecha - lote',
  'FieldInfo': 'Información del lote',
  'FieldLevel': 'Lote-Nivel',
  'FieldLocationPrompt': 'Presionar firmemente en el mapa para ubicar el lote o presionar el botón azul a la izquierda para usar su ubicación actual',
  'FieldManagement': 'Manejo de campo',
  'FieldMissingCurPolicyHarvest': 'Este lote no tiene póliza o cultivo asociado; presione aquí para vincular esta información.',
  'FieldReference': 'Referencia del lote',
  'FieldScoring': 'Puntuacion del Lote',
  'FieldShapeAndFarm': 'Perímetro de lote y establecimiento',
  'Fields': 'Lotes',
  'FileActions': 'Acciones de archivo',
  'FilterList': 'Filtrar el listado',
  'Filters': 'Filtros',
  'FirstAndLastName': 'Primer apellido',
  'FirstName': 'Nombre de pila',
  'FloodOrWaterloggingPast5Years?': '¿Ha habido alguna inundación o anegamiento en los últimos 5 años?',
  'FloodZone': 'Zona de inundacion',
  'ForgotPassword': 'Restablecer la contraseña',
  'FormSubmitted': 'Grabado!',
  'FrontPageReportDisclaimer': 'Sujeto a la validez de las garantias',
  'FungicideApplied': 'Fungicida aplicado',
  'GPSModuleUnavailable': 'Módulo GPS indisponible',
  'Gender': 'Género',
  'Generate': 'Generar',
  'GenerateReport': 'Generar Informe',
  'GeneratingReportPleaseWait': 'Generando informe, por favor esperar...',
  'GeographicalArea': 'Área Geográfica',
  'GetDirectionsField': 'Obtener indicaciones para el lote',
  'GrainMoisture': 'Humedad de los granos',
  'GrainMoistureReduction': 'Reducción debido a la humedad de los granos',
  'GrantsTo': 'Subvenciones a',
  'GrapeVariety': 'Uva Variedad',
  'GrossSampleProduction': 'Producción bruta por muestra',
  'GuaranteedLosses': 'Pérdidas cubiertas',
  'HUN': 'Hungría',
  'HailInfo': 'Este mapa muestra el potencial de daños por granizo.',
  'Harvest': 'Cultivo',
  'HarvestArea': 'Area del Cultivo',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'La superficie cosechada debe ser positiva y no mayor a la superficie del lote!',
  'HarvestFormComment': 'Informaciones adicionales de cosecha',
  'HarvestInfo': 'Información del cultivo',
  'HarvestLoss': 'Pérdida de cosecha',
  'HarvestModificationDisabledMsg': 'Esta cosecha no puede modificarse, ya que ha sido creada por el asegurador.',
  'HarvestPlotId': 'Sub-lote ID',
  'HarvestPractice': 'Tipo de cultivo',
  'HarvestReference': 'Referencia de campaña',
  'HarvestYear': 'Año de cosecha',
  'Harvester': 'Cosechadora',
  'HarvesterDistanceHarvested': 'Distancia cosechada',
  'HarvesterPlatformWidth': 'Ancho de cosecha de la plataforma',
  'Harvests': 'Campañas',
  'HarvestsWillNotBeUpdated': 'Las cosechas de este campo no se actualizarán.',
  'HelloReportIssue': 'Hola, me gustaría informar el siguiente problema encontrado con la aplicación GreenTriangle:',
  'HerbicideApplied': 'Herbicida aplicado',
  'HereAddAsNew': 'Haga clic aquí para agregarlo como un nuevo establecimiento.',
  'HideDataFromMap': 'Ocultar datos del mapa',
  'HighHailRisk': 'Potencial de daños por granizo alto',
  'HighPWind': 'Tormenta (28.5-32.5 m/s)',
  'HighPrecipitation': 'Alta precipitación (30-50 mm/semana)',
  'HighSoilMoisture': 'Ligeramente más húmedo de lo normal',
  'HighTemperature': 'Ola de calor ligero (25°C<Tmax<29°C)',
  'HighVegetation': 'Salud vegetativa ligeramente superior de lo normal (+25%)',
  'Higher': 'Superior',
  'HigherSoilMoisture': 'Más húmedo de lo normal',
  'HigherTemperature': 'Ola de calor moderada (29°C<Tmax<34°C)',
  'HigherVegetation': 'Salud vegetativa más alta de lo normal (+40%)',
  'Highest': 'Máximo',
  'HighestSoilMoisture': 'Significativamente más húmedo de lo normal',
  'HighestTemperature': 'Ola de calor fuerte (Tmax>34°C)',
  'HighestVegetation': 'Salud vegetativa muy por encima de lo normal (+55% o más)',
  'HistoricalDeclaredYields': 'Rendimientos históricos declarados',
  'HistoricalObservedYields': 'Rendimientos históricos observados',
  'HistoricalRealizedYields': 'Rendimientos Históricos',
  'Homogeneity': 'Homogeneidad',
  'HomogeneityInfo': 'La puntuación de Homogeneidad mide qué tan homogeneo ha sido un lote a lo largo de las campañas, con relación al desarrollo del cultivo.',
  'ID': 'IDENTIFICACIÓN',
  'ITA': 'Italia',
  'ImportData': 'Importar los datos',
  'Impurities': 'Impurezas',
  'Info': 'Info',
  'InheritedFrom': 'Heredado de',
  'Initials': 'Iniciales',
  'InsecticideApplied': 'Insecticida aplicado',
  'Insee': 'INSEE',
  'Install': 'Instalar',
  'Insure harvest': 'Asegurar la cosecha',
  'InsureAnotherHarvest': 'Asegurar otra cosecha',
  'InsureHarvest': 'Asegurar la cosecha',
  'Insured': 'Asegurado',
  'InsuredArea': 'Superficie Asegurada',
  'InsuredCropRequired': 'Seleccione al menos un cultivo asegurado para continuar.',
  'InsuredCrops': 'Cultivos asegurados',
  'InsuredFields': 'Campos asegurados',
  'InsuredHarvest': 'Cosecha asegurada',
  'InsuredHarvests': 'Cosechas aseguradas',
  'InsuredPercent': 'Porcentaje asegurado',
  'InsuredPrice': 'Costo del seguro',
  'InsuredProduction': 'Producción asegurada',
  'InsuredYield': 'Rendimiento garantizado',
  'InterfieldInfo': 'La capa de "comparación de lotes" muestra los valores absolutos del índice de vegetación y permite la comparación directa del estado de la vegetación general entre los diferentes lotes.',
  'InteryieldInfo': 'Estimativa de rendimiento presenta la previsión de rendimiento con base en sensoramiento remoto y datos climáticos, hasta la fecha establecida.',
  'IntrafieldInfo': 'La capa de variabilidad intra-lote muestra los valores normalizados del índice de vegetación para el lote monitoreado. Este se subdivide en 5 zonas de áreas iguales correspondientes a 20 % (o o quintil) de los valores del índice, del más bajo al más alto.',
  'InvalidShapeTraced': 'Tu forma no puede tener líneas que se crucen. Por favor dibuja una forma simple sin cruces.',
  'Irrigated': 'Irrigado',
  'IrrigatedQuestionMark': '¿Regado?',
  'IsBrokerLeader?': '¿Es el broker un líder?',
  'KernelsPerEar': 'Granos por espiga',
  'KernerlsPerUnit': 'Granos por unidad',
  'LTU': 'Lituania',
  'LastChanged': 'Último cambio',
  'LastName': 'Apellido',
  'Legend': 'Leyenda',
  'LessDetails': 'Menos detalles',
  'LinkCopiedToClipboard': 'El link fue copiado al clipboard',
  'LinkToThisLocation': 'Relacionar a esta ubicación',
  'List': 'Listado',
  'LiveSampleFeed': 'Muestras en vivo',
  'Loading': 'Cargando',
  'Locale': 'Idioma',
  'Location': 'Ubicación',
  'LocationAndFarm': 'Ubicación y establecimiento',
  'LocationMocked': 'Parece que tiene problema con su localización. Por favor, use su ubicación real para proseguir.',
  'LocationNotProvided': 'Su ubicación debe estar activada para usar esta función.',
  'Login': 'Conectarse',
  'Logout': 'Desconectarse',
  'LossAdjuster': 'Perito de campo',
  'LossCause': 'Motivo de la pérdida',
  'LossCauseRecognized': 'Causa de pérdida reconocida',
  'LossEstimation': 'Pérdida estimada',
  'LossEventDate': 'fecha del evento de pérdida',
  'Losses': 'Pérdidas',
  'LowHailRisk': 'Potencial de daños por granizo ligero',
  'LowPrecipitation': 'Baja precipitación (10-15 mm/semana)',
  'LowSoilMoisture': 'Ligeramente más seco de lo normal',
  'LowTemperature': 'Riesgo de heladas leves (-1°C<Tmin<2°C)',
  'LowVegetation': 'Salud vegetativa ligeramente por debajo de lo normal (-25%)',
  'LowWind': 'Viento fuerte (17-24.5 m/s)',
  'Lower': 'Inferior',
  'LowerSoilMoisture': 'Más seco de lo normal',
  'LowerTemperature': 'Riesgo moderado de heladas (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Salud vegetativa por debajo de lo normal (-40%)',
  'Lowest': 'Mínimo del lote',
  'LowestSoilMoisture': 'Significativamente más seco de lo normal',
  'LowestTemperature': 'Fuerte riesgo de heladas (-5°C>Tmin)',
  'LowestVegetation': 'Salud vegetativa muy por debajo de lo normal (-55% o menos)',
  'MAR': 'Marruecos',
  'MDA': 'Moldavia',
  'MEX': 'México',
  'ManageUserAccountAndSettings': 'Administre sus cuentas y configuraciones',
  'Manager': 'Gerente',
  'Many': 'Muchos',
  'Map': 'Mapa',
  'MaxSubsidiesPerMinute': 'Subsidios máximos por minuto',
  'MeasureDensity': 'Medir la densidad',
  'Median': 'Mediano',
  'Merge': 'Unir',
  'Message': 'Mensaje',
  'Method': 'Método',
  'MixedTemperature': 'Zona mixta de alta fluctuación de temperatura',
  'ModerateHailRisk': 'Potencial de daños por granizo moderado',
  'ModeratePrecipitation': 'Precipitación moderada (15-30 mm/semana)',
  'ModerateWind': 'Vendaval (24.5-28.5 m/s)',
  'Months': 'Meses',
  'MoreDetails': 'Más detalles',
  'MoreSpecificHarvestDetected': 'Se detecta una cosecha más específica que sustituirá a la cosecha existente.',
  'MunicipalMedianVegetation': 'Vegetación mediana del municipio',
  'Municipality': 'Partido/Departamento',
  'MustAddCustomer': 'Debe agregar al menos un cliente',
  'MustAddEmail': 'Debe proporcionar un correo electrónico válido',
  'MustSelectACustomer': 'Debe seleccionar un cliente para continuar',
  'MustSelectCropAndYear': 'Seleccione un cultivo y un año',
  'NDVIRange': 'Rango NDVI',
  'Name': 'Nombre',
  'NationalYield': 'Rendimiento nacional',
  'NeedCurrentHarvest': 'Debe agregar un cultivo y una cosecha para esta temporada antes de continuar',
  'NeedHelpContact': '¿Problemas con el acceso? - Contáctenos',
  'Neighbourhood': 'Vecindario',
  'New': 'Nuevo',
  'NewValue': 'Nuevo valor',
  'NewVersionWarning': 'Una nueva versión de la aplicación está disponible.',
  'NewVisit': 'Nueva visita',
  'Next': 'Próximo',
  'No': 'No',
  'NoClaims': 'Sin reclamos',
  'NoData': 'Ningún dato encontrado.',
  'NoDataFoundForYourFilters': 'No encontramos datos para sus criterios de filtro actuales.',
  'NoFarmEditors': 'Ningún editor asignado a este establecimiento',
  'NoGpsSupport': 'Este dispositivo no tiene una unidad GPS.',
  'NoHailRisk': 'Sin riesgo de daños',
  'NoOfflineFarms': 'No hay granjas guardadas para uso sin conexión.',
  'NoOngoingVisit': 'Favor hacer click em (+)  arriba para comenzar una nueva inspección',
  'NoPrecipitation': 'Ninguna precipitación (<5 mm/semana)',
  'NoRainstorm': 'Sin tormentas de lluvia (<15 mm/h)',
  'NoSampleDateSelectionWarning': 'No se ha seleccionado ninguna fecha de muestreo. Seleccione al menos uno para evitar tener un informe vacío.',
  'NoSheetDataError': 'El archivo Excel importado no tenía una hoja de "Data". Utilice la plantilla',
  'NoTelepacAvailable': 'Telepac no disponible',
  'NoVariety': 'Sin variedad',
  'NoWind': 'Brisa o sin viento (<17 m/s)',
  'NonCompensableLosses': 'Pérdidas no indemnizables',
  'NonIrrigated': 'Secano',
  'NonOrganic': 'Inorgánico',
  'NormalSoilMoisture': 'Situación normal',
  'NormalTemperature': 'Sin riesgo de Helada/Calor (2°C<Tmin; Tmax<25°C)',
  'NormalVegetation': 'Condición normal de vegetación',
  'NotAffected': 'No afectado',
  'NotAvailable': 'No disponible',
  'Number': 'Número',
  'NumberMatchingFields': 'Número de lotes correspondientes',
  'NumberMatchingSamples': 'Número de muestras correspondientes',
  'NumberOfFields': 'Número de lotes',
  'NumberOfGrainsPerRow': 'Número de granos por hilera',
  'NumberOfLossAdjusters': 'Número de expertos',
  'NumberOfPolicies': 'Número de pólizas',
  'NumberOfRowsPerHead': 'Número de filas por cabezal',
  'NumberOfSamples': 'Número de muestras',
  'Occurence': 'Ocurrencia',
  'OfFieldArea': 'de la superficie del lote',
  'OfficialHarvestArea': 'Zona oficial de cosecha',
  'Offline': 'Modo sin conexión',
  'OfflineAreaMaxLimitReachedMsg': 'Se alcanzó el límite máximo de área sin conexión.',
  'OfflineAreaTutorialDownload': 'Toque el botón de descarga para guardar mosaicos de mapas para uso sin conexión en las áreas seleccionadas.',
  'OfflineAreaTutorialPanMap': 'Mueva el mapa usando un dedo.',
  'OfflineAreaTutorialPinchZoom': 'Pellizque el mapa para acercar o alejar.',
  'OfflineAreaTutorialSelectArea': 'Toque un área para seleccionarla o deseleccionarla.',
  'OfflineAreas': 'Áreas sin conexión',
  'OfflineFarms': 'Granjas sin conexión',
  'Ok': 'De acuerdo',
  'Ongoing': 'En curso',
  'OpenCamera': 'Cámara abierta',
  'OpenFarm': 'Abrir el establecimiento',
  'OpenPolicy': 'Abrir la póliza',
  'OpenVisit': 'Visita abierta',
  'Or': 'O',
  'Organic': 'Orgánico',
  'OrganicQuestionMark': '¿Orgánico?',
  'OtherCompensableLosses': 'Otras pérdidas indemnizables',
  'OtherFields': 'Otros campos',
  'OverrideCalculation': 'Sobrescribir el rendimiento agregado',
  'OverrideEstimatedYield': 'Anular el rendimiento estimado',
  'Overview': 'Visión general',
  'PER': 'Perú',
  'POL': 'Polonia',
  'PRY': 'Paraguay',
  'Page': 'Página',
  'Password': 'Contraseña',
  'PasswordMissing': 'Por favor, agregue su contraseña!',
  'PastMonth': 'Mes pasado',
  'PastReportsClickToRefresh': 'Informes anteriores - actualizar:',
  'PastWeek': 'Semana pasada',
  'PastYear': 'Año pasado',
  'Pause': 'Pausa',
  'Paused': 'En pausa',
  'PeakVegetationDate': 'Fecha del pico de vegetación',
  'Pending': 'Pendiente',
  'PendingDownload': 'Pendiente de descarga',
  'PendingUpload': 'Carga pendiente',
  'PerCropSignature': 'Firma por cultivo',
  'Percent': 'Por ciento',
  'Phone': 'Teléfono',
  'Photo': 'Foto',
  'Photos': 'Fotos',
  'PhotosAndReferences': 'Fotos y referencias',
  'PixelResolution': 'Resolución de píxeles',
  'PlanetTiles': 'Imágenes Planet',
  'PlanetTilesBoundsWarning': 'No hay mosaicos disponibles para la fecha y el área seleccionadas. Seleccione una fecha o área diferente.',
  'PlantDensity': 'Densidad de plantas',
  'PlantsCollected': 'Plantas cosechadas',
  'PleaseAddFields': 'Agregue algunos lotes para continuar',
  'PleaseConnect': 'Sin conexión a Internet. Para hacer login, ¡conéctese a internet!',
  'PleaseEnableLocation': 'Por favor, comparta su ubicación.',
  'PleaseEnablePhotoPermissions': 'Para continuar, por favor habilitar la cámara en configuraciones',
  'PleaseEnterEmail': 'Por favor, introduzca su correo electrónico.',
  'PleaseEnterValue': 'Por favor, introduzca un valor',
  'PleaseReviewReportBeforeSigning': 'Por favor, revise el informe antes de firmar',
  'PleaseSelectClaim': 'Por favor seleccione reclamo',
  'PleaseSelectDate': 'Por favor seleccione una fecha.',
  'PleaseSelectFarm': 'Seleccione um establecimiento para continuar',
  'PleaseSelectField': 'Por favor seleccion un lote para continuar',
  'PleaseSelectLocation': 'Seleccione una ubicación para continuar.',
  'PleaseSelectPolicy': 'Por favor seleccione una sola póliza',
  'PleaseUpgradeLatest': 'Su versión de GreenTriangle está desactualizada. Actualice a la versión más reciente',
  'PointOrZone': 'Punto/Zona',
  'Policies': 'Pólizas',
  'Policy': 'Póliza',
  'PolicyFormComment': 'Informaciones adicionales de la póliza',
  'PolicyNumber': 'Número de póliza',
  'PolicyProcessingStartEnd': 'Inicio y fin del procesamiento de la póliza',
  'Polygon': 'Polígono',
  'Portfolio': 'portafolio',
  'PortfolioReport': 'Informe de cartera',
  'PotentialDuplicates': 'Las siguientes entradas tienen un aspecto similar. Tal vez desee seleccionar una de las siguientes opciones',
  'PoweredBy': 'Desarrollado por',
  'Practice': 'Práctico',
  'PrecipitationInfo': 'La precipitación semanal total denota agua líquida y congelada acumulada, incluida la lluvia y la nieve, que cae a la superficie de la Tierra en 7 días. No incluye niebla, rocío o la precipitación que se evapora en la atmósfera antes de tocar en la superficie de la Tierra.',
  'PredictedYield': 'Rendimiento previsto',
  'PreexistingPolicy?': '¿Ya existe una política para esta área?',
  'Premium': 'De primera calidad',
  'PremiumRatePercent': 'Porcentaje de tasa de prima',
  'Preview': 'Pre-visualizar',
  'PreviewAndSend': 'Pre-visualizar e Enviar',
  'Previous': 'Anterior',
  'PreviousDate-s': 'Fecha(s) Previa(s)',
  'PriceRetainedByExpert': 'Precio retenido por el experto.',
  'Primary': 'Primaria',
  'PrimaryYieldLoss': 'Pérdida de rendimiento',
  'PrivacyPolicy': 'Política de privacidad',
  'Processing': 'Procesamiento',
  'Productivity': 'Productividad',
  'ProductivityInfo': 'El índice de Productividad estima qué tan productivo ha sido un lote a lo largo de las campañas.',
  'QuoteProcessingStartEnd': 'Comienzo y fin del procesamiento de cotización',
  'ROU': 'Rumania',
  'RUS': 'Rusia',
  'RainstormInfo': 'Precipitación máxima por hora dentro de esta semana.',
  'RainstormLevel1': 'Lluvia de nivel 1 (15-25 mm/h)',
  'RainstormLevel2': 'Lluvia de nivel 2 (25-35 mm/h)',
  'RainstormLevel3': 'Lluvia de nivel 3 (35-50 mm/h)',
  'RainstormLevel4': 'Lluvia de nivel 4 (>50 mm/h)',
  'Recipients': 'Destinatarios',
  'RecognizedHazard': 'Evento identificado',
  'Reference': 'Referencia',
  'ReferenceYield': 'Rendimiento de referencia',
  'Refresh': 'Actualizar',
  'Region': 'Región',
  'RegionLevel': 'Nivel de región',
  'RegionLevel_commune': 'Comuna',
  'RegionLevel_country': 'País',
  'RegionLevel_state': 'Estado',
  'RegionalStatistic': 'Estadísticas regionales',
  'Regions': 'Regiones',
  'Reject': 'Rechazar',
  'RelativeScoring': 'Puntuación Relativa',
  'RelativeScoringInfo': 'La puntuación relativa refleja la diferencia entre la puntuación de un Lote con la puntuación mediana de los lotes de una región (usando cerca de 100 muestras), siendo que el percentil posiciona a la puntuación de un lote dentro de los lotes de una región (5 siendo el percentil superior, 1 el percentil inferior, 3 la mediana)',
  'Remove': 'Eliminar',
  'RemoveCrop': 'Eliminar Cultivo',
  'RemoveFarm': 'Eliminar establecimiento',
  'RemoveField': 'Eliminar lote',
  'RemoveHarvest': 'Eliminar cosecha',
  'RemoveLoss': 'Eliminar pérdida',
  'RemovedGroups': 'Grupos eliminados',
  'RenewHarvests': 'Renovar Campaña',
  'ReportDate': 'Fecha del informe',
  'ReportDoesntExist': 'El informe no existe',
  'ReportGenerationPageComment': 'Comentario general sobre el informe',
  'ReportNoFields': '¡Este establecimiento no tiene lotes sobre los que crear un informe!',
  'ReportOverview': 'Descripción general del informe',
  'Reset': 'Reiniciar',
  'ResetLink': 'Enviar correo electrónico para restablecer',
  'Restore': 'Recuperar datos',
  'Resume': 'Reanudar',
  'Risks': 'Riesgos',
  'RowDistanceCollected': 'Distancia de las líneas cosechadas',
  'RowsAndWidth': 'Líneas y distancia entre líneas',
  'SRB': 'Serbia',
  'Sample': 'Muestra',
  'Sample-s': 'Muestra(s)',
  'SampleCropTitle': 'Número de muestras por cultivo.',
  'SampleDate': 'Fecha de muestra',
  'SampleDate-s': 'Fecha(s) de muestra',
  'SampleFormComment': 'Comentario / Observación',
  'SampleFormMissingImagesWarning': 'No tomaste una foto para esta muestra. Por favor agregue uno para continuar.',
  'SampleLocation': 'Ubicación de la muestra',
  'SampleLocationInaccurate': 'Ubicación imprecisa - Active la ubicación precisa, caso aún no esté. En seguida, clique en "Use mi ubicación" nuevamente.',
  'SampleLocationOutsideField': 'El lugar de muestreo no está dentro del lote seleccionado.',
  'SampleLocationPrompt': 'Elegir el lote correspondiente en el mapa y presionar firmemente en el mapa para ubicar la muestra o presionar el botón azul a la izquierda para usar su ubicación actual.',
  'SampleType': 'Tipo de muestra',
  'SampledArea': 'Área muestreada',
  'SampledBy': 'Muestreado por',
  'Samples': 'Muestras',
  'SamplingDate-s': 'Fecha(s) de muestreo',
  'Save': 'Guardar',
  'SaveApprove': 'Guardar y aprobar',
  'SaveOffline': 'Guardar sin conexión',
  'Saving': 'Guardando...',
  'Score': 'Puntuación',
  'Search': 'Buscar',
  'SearchAddTipLabel': 'Búsqueda de términos combinados',
  'SearchExampleDescAddedBy': 'Escriba una dirección de correo electrónico para mostrar las muestras agregadas por ese usuario, así como los lotes y establecimientos de esas muestras',
  'SearchExampleDescAddress': 'Escriba una dirección para volver a centrar el mapa en esa ubicación.',
  'SearchExampleDescCropCondition': 'Escriba una condición de cultivo para resaltar las muestras en las que la condición del mismo se evaluó de cierta manera.',
  'SearchExampleDescFarm': 'Encuentre un establecimiento, sus lotes y muestras, por nombre o dirección',
  'SearchExampleDescHarvest': 'Encuentre todos los elementos relacionados con una cosecha específica ingresando un tipo de cultivo, temporada o año. Si ingresa un tipo de cultivo, los resultados se limitarán a este año. Puede buscar años de cosecha más antiguos en una segunda búsqueda.',
  'SearchExampleDescPolicy': 'Encuentre todos los elementos relacionados con un número de póliza',
  'SearchExampleLabelAddedBy': 'encontrar muestras agregadas por una dirección de correo electrónico',
  'SearchExampleLabelAddress': 'ciudad, dirección o país',
  'SearchExampleLabelCropCondition': 'encontrar muestras para una determinada condición de cultivo (p. ej. "mala")',
  'SearchExampleLabelFarm': 'buscar por nombre y dirección del establecimiento',
  'SearchExampleLabelHarvest': 'buscar por cosecha, p. ej. "trigo"',
  'SearchExampleLabelPolicy': 'buscar por número de póliza',
  'SearchExampleLabelUserGroup': 'encontrar um ítem en un portafolio específico(s)',
  'SearchForClaimNumber': 'Buscar por número de Siniestro',
  'SearchVisit': 'Localizar Inspección no asignada',
  'Secondary': 'Secundaria',
  'SecondaryYieldLoss': 'Pérdida secundaria',
  'SeedWeightPerHead': 'Peso de granos por capítulo',
  'Select': 'Selección',
  'SelectAll': 'Seleccionar todo',
  'SelectAppellation': 'Seleccionar denominación',
  'SelectCostType': 'Seleccionar tipo de costo',
  'SelectCropCondition': 'Seleccionar la condición del cultivo.',
  'SelectCropType': 'Seleccionar un tipo de cultivo',
  'SelectCurHarvest': 'Seleccione el cultivo actual',
  'SelectDate': 'Seleccione fecha',
  'SelectFarm': 'Seleccionar un establecimiento',
  'SelectFarmsForOfflineAccess': 'Seleccione granjas para acceso sin conexión',
  'SelectField': 'Seleccionar un lote',
  'SelectFieldCurHarvest': 'Seleccione el año de cosecha',
  'SelectFieldCurPolicy': 'Seleccione la póliza del lote',
  'SelectHarvest': 'Seleccionar cosecha',
  'SelectHarvestYear': 'Seleccione el año de cosecha',
  'SelectLossCause': 'Elegir el motivo de la pérdida',
  'SelectOneGroup': 'Seleccione itens en apenas un portafolio',
  'SelectPac': 'Seleccione un archivo válido (.ZIP, .XML, .KML, GeoJSON). Si está seguro de que este archivo es correcto, contáctenos en support@green-triangle.com.',
  'SelectPolicy': 'Seleccionar una póliza',
  'SelectPortfolio': 'Seleccionar portafolio',
  'SelectRegions': 'Seleccione regiones',
  'SelectReportType': 'Seleccionar el tipo de informe',
  'SelectSampleType': 'Seleccionar el tipo de muestra',
  'SelectSubplotId': 'Seleccione ID de subtrama',
  'SelectVariety': 'Seleccionar Variedad',
  'SelectVegetationStage': 'Seleccionar la fase del cultivo (BBCH)',
  'SelectVisitType': 'Seleccionar tipo de inspección',
  'SelectWillOverwriteShape': '¿Está seguro que quiere elegir este perímetro? El perímetro actual será substituido.',
  'SelectYear': 'Seleccionar el año',
  'SelectedAppellation': 'Denominación de origen seleccionada',
  'SelectedCrops': 'Cultivos seleccionados',
  'SelectedField': 'Campo seleccionado',
  'Send': 'Enviar',
  'SendFeedback': 'Enviar comentarios',
  'SendTo': 'Enviar a',
  'Sending': 'Enviando',
  'Sent': 'Enviado',
  'Settings': 'Configuraciones',
  'SevereHailRisk': 'Potencial de daños por granizo muy alto',
  'SeverePrecipitation': 'Precipitaciones intensas (75-100 mm/semana)',
  'SevereWind': 'Huracán (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Toque el mapa para agregar un punto o toque un punto para editarlo o eliminarlo.',
  'ShapeTracerEditPoint': 'Mueva el punto con el alfiler o toque en cualquier otro lugar para agregar nuevos puntos.',
  'Share': 'Compartir',
  'ShowAll': 'Mostrar todo',
  'ShowClosed': 'Mostrar cerrado',
  'ShowDataOnMap': 'Mostrar datos en el mapa',
  'ShowLastAdded': 'Mostrar último añadido',
  'ShowOnMap': 'Mostrar en el mapa',
  'ShowSamplesAddedIn': 'Mostrar muestras agregadas en el último:',
  'SignAndClose': 'Firmar y Cerrar',
  'SignatoryName': 'Nombre de quien firma',
  'SignatoryNames': 'Nombre de los firmantes',
  'Signature': 'Firma',
  'Signatures': 'Firmas',
  'SignedByFarmer': 'Firmado por el agricultor',
  'SnowIce': 'Hielo de nieve',
  'SoilMoistureInfo': 'El índice de anomalía de humedad del suelo cuantifica el estado del agua de la capa de 40 cm de profundidad en relación con el promedio de los últimos años (10 años) para el mismo período.',
  'SomethingWentWrongError': 'Algo salió mal.',
  'SowingDate': 'Fecha de siembra',
  'SowingDensity': 'Densidad de siembra',
  'SpaceBetweenPlants': 'Espacio entre plantas',
  'SpaceBetweenRows': 'Espacio entre líneas',
  'SquareArea': 'Área de la plaza',
  'SquareMeterCut': 'Corte de metro cuadrado / densidad de plantas',
  'SquareSide': 'Lado del cuadrado',
  'SquareSize': 'Tamaño del cuadrado',
  'Stability': 'Estabilidad',
  'StabilityInfo': 'La puntuación de Estabilidad mide qué tan estable ha sido la Productividad a lo largo de las campañas.',
  'StartDate': 'Fecha de inicio',
  'StartDateTime': 'Fecha y hora de inicio',
  'StartingYield': 'Rendimiento viable',
  'State': 'Estado',
  'Status': 'Estado',
  'SubjectToValidationOfReseedingByExpert': 'Sujeto a validación de resiembra por parte del experto',
  'Submitted': 'Enviado',
  'Subplots': 'Subtramas',
  'Subsidy': 'Subvención',
  'SubsidyError_INSURED_ADDRESS_MISSING': 'Falta la dirección del asegurado.',
  'SubsidyError_INSURED_DATA_MISSING': 'Faltan datos del asegurado.',
  'SubsidyError_INSURED_EXTERNAL_ID_MISSING': 'Falta el número de documento del asegurado.',
  'SubsidyError_INSURED_ZIP_CODE_MISSING': 'Falta el código postal del asegurado.',
  'SubsidyError_VALID_FROM_MISSING': 'Falta la fecha de.',
  'SubsidyError_VALID_TO_MISSING': 'Falta la fecha.',
  'SubsidySettings': 'Configuración de subsidios',
  'SubsidyStatus': 'Situación de subvención',
  'SumInsured': 'Suma asegurada',
  'Summary': 'Resumen',
  'SupportedSurface': 'Superficie apoyada',
  'SureAddSampleWithoutField': 'Está seguro que desea adicionar una muestra sin un lote relacionado?',
  'SureCancel': '¿Está seguro de que desea cancelar antes de guardar los datos?',
  'SureCloseVisit': 'Está seguro que desea finalizar la Inspección? El informe no fue firmado.',
  'SureCloseVisitPartial': 'Está seguro que desea finalizar la Inspección? El informe fue solamente firmado parcialmente.',
  'SureDeleteFarm': '¿Está seguro de que desea eliminar este establecimiento?',
  'SureDeleteField': '¿Está seguro de que desea eliminar este lote?',
  'SureDeleteHarvest': 'Está seguro que quiere borrar esta cosecha?',
  'SureDeleteSample': '¿Está seguro de que desea eliminar esta muestra?',
  'SureDeleteVisit': 'Está seguro que desea borrar esta Inspección?',
  'SureReset': '¿Está seguro que quiere reiniciar? Perderás todos tus cambios sin conexión.',
  'SureSelectObject': '¿Está seguro de que, en su lugar, desea seleccionar lo siguiente?',
  'SureSignOut': '¿Seguro que quiere cerrar la sesión? Los datos guardados en el modo fuera de línea se perderán si se desconecta.',
  'SurfacePAC': 'PAC de superficie',
  'SurfaceTemperatureInfo': 'Esta capa despliega temperatura mínima y máxima semanal en superficie, indicando el riesgo de helada y golpe de calor',
  'Syncing': 'Sincronizando',
  'TableNoRowsFound': 'No se encontró línea',
  'TableOf': 'de',
  'TablePage': 'Página',
  'TableRows': 'Línea',
  'TelepacAvailableFor': 'Telepac cargado para',
  'TelepacNr': 'numero telepac',
  'TelepacReport': 'Importación de PAC',
  'TemperatureInfo': 'Esta capa muestra las temperaturas máximas y mínimas semanales para detectar el riesgo de heladas y olas de calor.',
  'Tertiary': 'Terciaria',
  'TertiaryYieldLoss': 'Pérdida terciaria',
  'ThousandKernelWeightGrams': 'Peso de 1000 granos (gr)',
  'TopNCropsByHarvestArea': 'Principales cultivos por zona de cosecha',
  'Total': 'Total',
  'TotalArea': 'Área total',
  'TotalCultivatedArea': 'Área cultivada (total)',
  'TotalEarsDetected': 'Total de orejas detectadas',
  'TotalHarvestArea': 'Superficie total de cosecha',
  'TotalLoss': 'Pérdida total',
  'TotalYieldPctLoss': 'Pérdida de rendimiento total (%)',
  'UKR': 'Ucrania',
  'URY': 'Uruguay',
  'USA': 'Estados Unidos',
  'UnableToUpload': 'No se puede cargar',
  'UnassignMyself': 'Desasignarme',
  'Uncategorized': 'Sin categoría',
  'Undo': 'Cancelar',
  'UnguaranteedLosses': 'Pérdidas no cubiertas',
  'Unit': 'Unidad',
  'UnitsPerPlant': 'Unidades por planta',
  'UnknownErrorOccurred': 'Se produjo un error desconocido',
  'UpcomingInspection': 'Próxima inspección',
  'Update': 'Actualizar',
  'UpdateMemberships': 'Actualizar membresías',
  'UploadPACFile': 'Enviar el archivo PAC',
  'Usage': 'Uso',
  'UseMyLocation': 'Use mi ubicación',
  'User': 'Usuario',
  'UserAdminNoAccess': 'No hay superposición entre los clientes de los usuarios seleccionados y sus derechos de usuario-administrador.',
  'UserAdminNoCommon': 'Los usuarios seleccionados no tienen empresa común.',
  'UserAdministration': 'Administración de Usuario',
  'UserGroup': 'Portafolio',
  'Validation': 'Validación',
  'Validity': 'Validez',
  'VegetationInfo': 'La anomalía de vitalidad de la planta ofrece una medida alternativa de la condición relativa de los cultivos en comparación con años anteriores (desde 2001). Este indicador puede usarse para monitorear áreas donde la vegetación está estresada o como parámetro para detectar una ola de calor o una posible sequía.',
  'VegetationStage': 'Etapa del cultivo',
  'VeryHighPrecipitation': 'Muy altas precipitación (50-75 mm/semana)',
  'VeryLowPrecipitation': 'Muy baja precipitación (5-10 mm/semana)',
  'ViewThisInstead': '¿Le gustaría salir de este formulario y abrir el siguiente en su lugar?',
  'VinificationRatio': 'Relación de vinificación (kg/hl)',
  'Visit': 'Inspección',
  'VisitAssign': 'Firmar inspección',
  'VisitCandidateStatus_accepted': 'Aceptado',
  'VisitCandidateStatus_assigned': 'Asignado',
  'VisitCandidateStatus_rejected': 'Rechazado',
  'VisitEmailBody': 'Hola,\n\nAdjunto encontrará el informe pericial sobre los daños causados por el clima y el granizo.\n\nPara cualquier pregunta relacionada con este expediente, póngase en contacto con el experto a cargo del mismo.\n\nGracias,\n\nEl equipo de GreenTriangle',
  'VisitList': 'Lista de Inspección',
  'VisitMode': 'Modo de Inspección',
  'VisitReport': 'Informe de inspección',
  'VisitReportEmailInfoMsg': 'Esto enviará el informe de visita firmado a las direcciones de correo electrónico seleccionadas.',
  'VisitType': 'Tipo de Inspección',
  'VisitValidation': 'Validación de la inspección',
  'VisitYear': 'Año de visita',
  'Visited': 'Inspeccionado',
  'Visits': 'Visitas',
  'Volatility': 'Volatilidad',
  'WebApp': 'Aplicación Web',
  'WeightPerPlant': 'Peso promedio por planta',
  'WeightPerUnit': 'Peso por unidad',
  'WeightedAverage': 'Peso promedio',
  'WindInfo': 'La capa de ráfaga de viento máxima semanal representa la ráfaga de viento máxima registrada en los últimos 7 días a una altura de diez metros sobre la superficie de la Tierra.',
  'Withdrawal': 'Cancelar',
  'Withdrawn': 'Retirado',
  'WouldLikeToRestore': 'Identificamos que datos de este formulario no han sido guardados. Quiere recuperarlos?',
  'Year': 'Año',
  'YearEg': 'Año (por ej. 2019)',
  'YearsOfAgriculture?': '¿Cuantos años lleva usted en la agricultura?',
  'Yes': 'Si',
  'YieldAndLoss': 'Rendimiento y pérdida',
  'YieldCalcUnsupportedCrop': 'La calculadora no es soportada por este tipo de cultivo. Actualmente, solo es posible atribuir densidad de plantas.',
  'YieldEstimationMethod': 'Método de estimación de rendimiento',
  'YieldLoss': 'Pérdida de rendimiento',
  'YieldLossInputHidden': 'La entrada de pérdida de rendimiento está deshabilitada porque no se reconoce la causa de la pérdida.',
  'YieldRatio': 'Índice de rendimiento',
  'YieldTooLarge': 'Rendimiento muy alto',
  'YouMayTryAgain': 'Puede intentarlo nuevamente',
  'ZAF': 'Sudáfrica',
  'ZipCode': 'Código postal',
  'ZoomInViewLayer': 'Para ver esta capa, amplíe.',
  'ZoomOutViewLayer': 'Para ver esta capa, reduzca la imagen.',
  'additional-costs': 'Costos adicionales',
  'agroforestry': 'Agro-forestal',
  'alfalfa': 'Alfalfa',
  'allSynced': 'Todo fue sincronizado con la base de datos',
  'almond': 'Almendro',
  'ananas': 'Ananas',
  'angelica': 'Angélica',
  'anise': 'Anis',
  'apples': 'Manzanas',
  'apples-cider': 'Manzano (sidra)',
  'apples-pre-prod': 'Manzano (5 años o menos)',
  'apricots': 'Damasco',
  'apricots-pre-prod': 'Damasco (5 años o menos)',
  'aromatic-plants': 'Plantas aromáticas',
  'artichoke': 'Alcachofa',
  'asparagus': 'Espárrago',
  'avocado': 'Palto',
  'bananas': 'Banano',
  'barley': 'Cebada',
  'barley-6-row': 'Cebada de 6 lineas',
  'barley-malting': 'Cebada maltera',
  'barley-seeds': 'Cebada (semilla híbrida)',
  'base': 'Base',
  'basic': 'Básico',
  'basil': 'Albahaca',
  'bbch-00-grapes': 'A. Botón de Invierno - Uva',
  'bbch-05-grapes': 'B. Hinchado de yema - Uva',
  'bbch-09-grapes': 'C. Yema con punta verde - Uva',
  'bbch-11-grapes': 'D. Emergencia foliar - Uva',
  'bbch-13-grapes': 'E. Hojas expandidas - Uva',
  'bbch-53-grapes': 'F. Inflorescencias visibles - Uva',
  'bbch-55-grapes': 'G. Inflorescencias separadas - Uva',
  'bbch-57-grapes': 'H. Botones florales separados - Uva',
  'bbch-64-grapes': 'I. Floración - Uva',
  'bbch-71-grapes': 'J. Fructificación - Uva',
  'bbch-75-grapes': 'K. Bayas tamaño de arveja - Uva',
  'bbch-77-grapes': 'L. Bayas tocando - Uva',
  'bbch-81-grapes': 'M. Inicio de Enverado - Uva',
  'bbch-89-grapes': 'N. Madurez - Uva',
  'bbch-93-grapes': 'O. Madurez de ramas/Caída foliar - Uva',
  'beans': 'Habas y haboncillos',
  'beans-dry': 'Poroto seco',
  'beans-green': 'Poroto verde',
  'beans-red': 'Poroto colorado',
  'beans-seeds': 'Poroto (semilla)',
  'beans-white': 'Poroto alubia',
  'beetroot': 'Remolacha',
  'benchmark-yield': 'Rendimiento de referencia',
  'black-medick': 'Lupulina-trebolillo',
  'blackberry': 'Mora',
  'blackcurrant': 'Grosella negra',
  'blueberry': 'Arándano',
  'broccoli': 'Brócoli',
  'bromes': 'Bromo inerme',
  'buckwheat': 'Trigo sarraceno',
  'burdock': 'Bardana',
  'cabbages': 'Repollo',
  'cabbages-feed': 'Repollo forrajero',
  'cabbages-red': 'Repolho rojo',
  'camelina': 'Camelina',
  'caraway': 'Comino de prado',
  'cardoon': 'Cardo',
  'carob': 'Algarrobo',
  'carrots': 'Zanahoria',
  'carrots-feed': 'Zanahoria forrajera',
  'carrots-young': 'Zanahoria',
  'cassava': 'Mandioca',
  'castor-bean': 'Ricino',
  'castration-costs': 'Costos de Castración ($)',
  'cauliflowers': 'Coliflor',
  'cedrate': 'Cidrera',
  'celeri-leaves': 'Apio (hoja)',
  'celeri-root': 'Apio (raiz)',
  'celery': 'Apio',
  'cereals': 'Cereales',
  'chamomile': 'Camomilla',
  'chard': 'Acelga',
  'cherries': 'Cerezas',
  'chervil': 'Cerefolio',
  'chestnut': 'Castaña',
  'chia': 'Chia',
  'chickpeas': 'Garbanzos',
  'chicory': 'Chicoria',
  'chilli': 'Chile',
  'chives': 'Ciboulette',
  'citrus': 'Citrus',
  'claim-all': 'Cierre definitivo de la misión',
  'claim-delivery': 'Cierre definitivo de resultados de cosecha',
  'claim-some': 'Cierre definitivo de la cultura',
  'claims-visit': 'Siniestro-Final',
  'claims-visit-file': 'estimar',
  'clementines': 'Clementina',
  'clover': 'Trébol',
  'cocoa': 'Cacao',
  'coffea': 'Café',
  'cold': 'Frio',
  'compensation-costs': 'Indemnización de costos',
  'connected': 'La aplicación está conectada a internet.',
  'coriander': 'Coriandro',
  'corn': 'Maíz',
  'corn-grain': 'Maíz (Grano)',
  'corn-popcorn': 'Maíz pisingallo',
  'corn-seeds': 'Maíz (semilla)',
  'corn-seeds-fertile': 'Maíz (semilla, fértil)',
  'corn-seeds-sterile': 'Maíz (semilla, estéril)',
  'corn-silage': 'Maíz (Ensilaje)',
  'corn-sweet': 'Maíz dulce',
  'corn-sweet-seeds-fertile': 'Maíz dulce (semilla, fértil)',
  'corn-sweet-seeds-sterile': 'Maíz dulce (semilla, estéril)',
  'corn-waxy': 'Maíz ceroso (waxy)',
  'cornsalad': 'Maíz',
  'cotton': 'Algodón',
  'cover-crop': 'Cultivo de Cobertura',
  'cranberry': 'Cranberry',
  'cress': 'Mastuerzo',
  'crop-mon': 'Seguimiento de cultivos',
  'crop_condition': 'Estado de los cultivos',
  'crosne': 'Alcaucil chino',
  'cucumbers': 'Pepino',
  'cumin': 'Comino',
  'cynara': 'Alcaucil',
  'dactylis': 'Dáctil',
  'damson': 'Ciruelo damson',
  'decree': 'Decreto oficial',
  'development': '4. Desarrollo de las partes cosechables de las plantas',
  'dill': 'Eneldo',
  'disease': 'Enfermedad',
  'downpour': 'Lluvia torrencial',
  'drought': 'Sequía',
  'eggplant': 'Berenjena',
  'elder': 'Sauco',
  'endives': 'Endivia',
  'excess-water': 'Exceso de agua',
  'excessive-air-humidity': 'Exceso de humedad (aire)',
  'expected-loss': 'Costos de pérdida esperada',
  'fallow-land-perennial': 'Barbecho (perenne)',
  'fallow-land-spring': 'Barbecho (primavera)',
  'false': 'No',
  'female': 'Femenino',
  'fennel': 'Hinojo',
  'fenugreek': 'Trigonella',
  'festuca': 'Festuca',
  'field-beans': 'Poroto',
  'field-beans-seeds': 'Poroto (semilla)',
  'field-crops': 'Grandes cultivos',
  'field-mustard': 'Nabo',
  'figs': 'Higo',
  'fire': 'incendio',
  'first-crop': '1ra',
  'flageolet-bean': 'Poroto (flageolet)',
  'flood': 'Fuertes precipitaciones',
  'flood-risk': 'Riesgo de inundación',
  'flowering': '6. Floración',
  'fodder-legumes': 'Leguminosas forrajeras',
  'forest': 'Bosque',
  'foxtail-millet': 'Mijo (foxtail)',
  'frost': 'Helada',
  'fruit-development': '7. Desarrollo de frutos',
  'full': 'Acceso completo',
  'garlic': 'Ajo',
  'gentiana': 'Gentiana',
  'germination': '0. Germinación / desarrollo de cotiledones',
  'gherkins': 'Pepinillo',
  'ginger': 'Gengibre',
  'go': '¡Vamos!',
  'good': 'Bueno',
  'gooseberries': 'Grosella',
  'gourd': 'Acocote - Calabaza de Peregrino',
  'grapefruit': 'Pomelo',
  'grapes': 'Uva',
  'grapes-food': 'Uva de mesa',
  'grapes-juice': 'Uva (jugo)',
  'grapes-must': 'Uva (mosto)',
  'grapes-nursery': 'Vivero de Vid',
  'grapes-table': 'Uva (mesa)',
  'grassland': 'Pastura',
  'grassland-perennial': 'Pastura (perenes)',
  'green-peas': 'Arveja verde',
  'group-allocator': 'Asignador de grupo',
  'hail': 'Granizo',
  'harvest': 'Cosechado',
  'harvest_crop': 'Tipo de cultivo',
  'harvested': 'Cosechado',
  'harvesting-costs': 'Costos de Cosecha ($)',
  'hazelnuts': 'Avellano',
  'heat-wave': 'Ola de calor',
  'heatstroke': 'Golpe de calor',
  'heavy-rainfall': 'Lluvias intensas',
  'hemp': 'Cáñamo',
  'hemp-paper': 'Cáñamo (papel)',
  'hemp-seeds': 'Cáñamo (semilla)',
  'high-res-mapbox': 'Alta resolución',
  'historical-yield': 'Relación de rendimiento histórica',
  'hop': 'Lúpulo',
  'hyssop': 'Hysopo',
  'inflorescence': '5. Emergencia de la inflorescencia / Espiga',
  'interfield': 'Comparación de lotes',
  'interfield-a': 'Sin vegetación',
  'interfield-b': 'Vegetación escasa',
  'interfield-c': 'Vegetación baja',
  'interfield-d': 'Vegetación moderada',
  'interfield-e': 'Vegetación moderada a densa',
  'interfield-f': 'Vegetación densa',
  'interfield-g': 'Actividad de vegetación alta',
  'interfield-h': 'Actividad de vegetación muy alta',
  'interfield-i': 'Actividad de vegetación maxima',
  'interyield': 'Estimación de rendimiento',
  'interyield-a': 'Rendimiento extremamente bajo',
  'interyield-b': 'Rendimiento muy bajo',
  'interyield-c': 'Rendimiento bajo',
  'interyield-d': 'Rendimiento de bajo a moderado',
  'interyield-e': 'Rendimiento moderado',
  'interyield-f': 'Rendimiento de moderado a alto',
  'interyield-g': 'Rendimiento alto',
  'interyield-h': 'Rendimiento muy alto',
  'interyield-i': 'Rendimiento máximo',
  'intrafield': 'Variabilidad intra-lote',
  'itemsToUpload': 'Todavía hay elementos para transferir a la base de datos.',
  'jerusalem-artichoke': 'Topinambur',
  'kale': 'Col (rizada)',
  'kiwi': 'Kiwi',
  'lack-of-sunlight': 'Baja luminosidad',
  'lavandin': 'Lavandin',
  'lavender': 'Lavanda',
  'leaf-development': '1. Desarrollo foliar',
  'leek': 'Puerro',
  'lemon-balm': 'Cedrón',
  'lemons': 'Limonero',
  'lentils': 'Lentejas',
  'lentils-berry': 'Lentejas verdes',
  'lentils-puy': 'Lentejas verdes (puy)',
  'lettuce': 'Lechuga',
  'linden': 'Tilo americano',
  'lineseed-textile-seeds': 'Lino',
  'linseed': 'Lino',
  'linseed-oilseed': 'Lino (aceite)',
  'linseed-textile': 'Lino textil',
  'linseed-textile-fiber': 'Lino (textil)',
  'linseed-textile-seeds': 'Lino (semilla)',
  'linseed-textile-straw': 'Lino (fibra)',
  'lotus': 'Lotus',
  'lupin': 'Lupino',
  'lupin-sweet': 'Lupino dulce',
  'male': 'Masculino',
  'mandarins': 'Mandarino',
  'mangos': 'Mango',
  'meadow-grass': 'Espiguilla',
  'medicinal-plants': 'Plantas medicinales',
  'melilotus': 'Melilotus',
  'melons': 'Melón',
  'membership_type': 'tipo de membresía',
  'meslin': 'Meslin',
  'millet': 'Mijo',
  'mint': 'Menta',
  'mirabelles': 'Ciruelo amarillo',
  'miscanthus': 'Miscanthus',
  'mixed-cereal': 'Cereales consociados',
  'monitoring-visit': 'Monitoramento',
  'monitoring-visit-file': 'supervisión',
  'monitoring-with-review': 'Aprovisionamiento/seguimiento (con gestión, sin aprovisionamiento)',
  'monitoring-with-review-provisioning': 'Aprovisionamiento/seguimiento (con gestión, con aprovisionamiento)',
  'monitoring-without-review': 'Aprovisionamiento/monitorización (sin gestión, sin aprovisionamiento)',
  'monitoring-without-review-provisioning': 'Aprovisionamiento/supervisión (sin gestión, con aprovisionamiento)',
  'month_0': 'Ene',
  'month_1': 'Feb',
  'month_10': 'Nov',
  'month_11': 'Dic',
  'month_2': 'Mar',
  'month_3': 'Abr',
  'month_4': 'May',
  'month_5': 'Jun',
  'month_6': 'Jul',
  'month_7': 'Ago',
  'month_8': 'Sep',
  'month_9': 'Oct',
  'most-recent': 'Más reciente',
  'mustard': 'Mostaza',
  'nashi-pear': 'Pera asiática',
  'nectarines': 'Ciruela',
  'noOfflineAreas': 'Para usar GreenTriangle sin conexión, es mejor descargar los mapas en su dispositivo. Presione Si para hacerlo ahora.',
  'none': 'Ninguna',
  'normal': 'Normal',
  'notConnected': 'La aplicación no está conectada a internet.',
  'not_available': 'No disponible',
  'not_planted': 'No sembrado',
  'nursery': 'Vivero',
  'oats': 'Avena',
  'oats-seeds': 'Avena (grano)',
  'oilseeds': 'Oleaginosas',
  'olives': 'Olivos',
  'onions': 'Cebollas',
  'oranges': 'Naranjo',
  'orchard': 'Frutales (más de 5 años)',
  'orchard-pre-prod': 'Frutales (5 años o menos)',
  'orchards': 'Frutales',
  'oregano': 'Orégano',
  'other': 'otro',
  'other-aromatic-medicinal': 'Otras plantas aromáticas o medicinales',
  'other-berries': 'Otros berries',
  'other-cereals': 'Otros cereales',
  'other-feed': 'Otros cultivos forrajeros',
  'other-fruits': 'Otras frutas',
  'other-industry': 'Otros cultivos industriales',
  'other-legumes': 'Otras leguminosas',
  'other-loss': 'Otras pérdidas',
  'other-oilseed': 'Otros productos oleaginosos',
  'other-textile': 'Otras plantas textiles',
  'other-vegetables': 'Otros vegetales',
  'parnsip': 'Apio',
  'parsley': 'Perejil',
  'peach-blood': 'Durazno sangre',
  'peach-flat': 'Durazno chato',
  'peaches': 'Durazno',
  'peaches-pre-prod': 'Durazno (5 años o menos)',
  'peanuts': 'Maní',
  'pears': 'Peras',
  'pears-pre-prod': 'Pera (5 años o menos)',
  'peas': 'Guisantes',
  'peas-feed': 'Arveja (ración)',
  'peas-seeds': 'Arveja (semilla)',
  'peas-split': 'Arveja',
  'pepper': 'Pimiento',
  'perennial': 'Perenne',
  'perennial-crops': 'Cultivos perennes',
  'persimmon': 'Caqui',
  'pest': 'Plagas',
  'pickle': 'Pepino (conserva)',
  'pineapples': 'Ananas',
  'pistachio': 'Pistacho',
  'pleaseCheckForm': 'Verifique los campos del formulario e intente nuevamente.',
  'plum': 'Ciruelo',
  'policy': 'Política',
  'polyculture': 'Consociadas',
  'poor': 'Mala',
  'poppy': 'Amapola',
  'potatoes': 'Patatas',
  'potatoes-industry': 'Papa (industria)',
  'potatoes-seeds': 'Papa (Semilla)',
  'potatoes-starch': 'Papa (almidón)',
  'potatoes-ware': 'Papa (consumo)',
  'precipitation': 'Precipitación total',
  'predicted-yield': 'Relación de rendimiento prevista',
  'preinspection-none': 'Evaluación de riesgos',
  'preinspection-visit': 'Previa',
  'preinspection-visit-file': 'inspección previa',
  'production-costs': 'Costos de Produccion ($)',
  'pulses': 'Leguminosas',
  'pumpkin': 'Calabaza',
  'purification-costs': 'Costos de Purificación ($)',
  'quinces': 'Membrillo',
  'quinoa': 'Quinoa',
  'quote_accepted': 'Propuesta aceptada',
  'quote_created': 'Propuesta creada',
  'quote_rejected': 'Propuesta rechazada',
  'radishes': 'Rábano',
  'rainstorm': 'Tormenta de lluvia',
  'rapeseed': 'Colza',
  'rapeseed-feed': 'Colza (forraje)',
  'rapeseed-seeds': 'Colza (semilla híbrida)',
  'raspberry': 'Frambuesa',
  'ray-grass': 'Ray-grass',
  'redcurrant': 'Grosella',
  'remaining-crops': 'Otros cultivos',
  'replanting': 'Replantación',
  'resowing-costs': 'Costo de resiembra',
  'rhubarb': 'Ruibarbo',
  'rice': 'Arroz',
  'ripening': '8. Maduración de frutos o semillas',
  'rocket': 'Rúcula',
  'root-crops': 'Tubérculos',
  'rosemary': 'Romero',
  'rutabaga': 'Nabo',
  'rye': 'Centeno',
  'rye-seeds': 'Centeno (semilla híbrida)',
  'safflower': 'Cártamo',
  'sage': 'Salvia',
  'sainfoin': 'Esparceta',
  'salad': 'Lechuga',
  'salsify': 'Salsifí',
  'salvia': 'Sálvia',
  'sandstorm': 'Tormenta de arena',
  'satellite': 'Satélite',
  'satureja': 'Saturejas',
  'second-crop': '2da',
  'senescence': '9. Senescencia o comienzo de la dormancia',
  'shallots': 'Echalote',
  'side-shoot': '2. Formación de macollos',
  'snow': 'Nieve',
  'snow-peas': 'Arveja (chaucha)',
  'soil-moisture': 'Humedad del suelo',
  'sorghum': 'Sorgo (Grano)',
  'sorghum-feed': 'Sorgo forrajero',
  'sorghum-seeds': 'Sorgo (semilla híbrida)',
  'sorghum-silage': 'Sorghum (Ensilaje)',
  'sorrel': 'Acedera comun',
  'soybeans': 'Soja',
  'soybeans-seeds': 'Soja (grano)',
  'spelt': 'Trigo espelta',
  'spinach': 'Espinaca',
  'spring': 'Primavera',
  'sprouts': 'Repollito de Bruselas',
  'squash': 'Calabaza',
  'squash-max': 'Calabaza (Cucurbita maxima)',
  'static-data': 'Datos del país',
  'stevia': 'Estevia',
  'storm': 'Tormenta',
  'strawberry': 'Frutilla',
  'sugar-beet': 'Remolacha',
  'sugar-beet-feed': 'Remolacha forrajera',
  'sugar-beet-seeds': 'Remolacha (semilla)',
  'sugar-cane': 'Caña de azúcar',
  'sugar-cane-feed': 'Caña de azúcar (ración)',
  'sunburn': 'Quemado por el sol',
  'sunflower': 'Girasol',
  'sunflower-oilseed': 'Girasol',
  'sunflower-seeds': 'Girasol (semilla)',
  'surface-temperature': 'Heladas & Golpe de Calor (superficie)',
  'switchgrass': 'Panicum',
  'syncDataDb': 'Sincronización de datos',
  'tarragon': 'Estragón',
  'temperature': 'Riesgo de heladas y calor',
  'thistle': 'Cardo',
  'thyme': 'Tomillo',
  'timothy-grass': 'Cola de Zorro - fleo',
  'tobacco': 'Tabaco',
  'tobacco-blond': 'Tabaco rubio',
  'tobacco-brown': 'Tabaco marrón',
  'tobacco-burley': 'Tabaco Burley',
  'tobacco-virginie': 'Tabaco Virginia',
  'tomatoes': 'Tomates',
  'tomatoes-processing': 'Tomate (industria)',
  'tons': 't',
  'tornado': 'Tornado',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (semilla)',
  'true': 'Si',
  'turmeric': 'Cúrcuma',
  'turnip': 'Nabo',
  'turnip-feed': 'Nabo forrajero',
  'unit': 'unidad',
  'unknown': 'Desconocido',
  'urtica': 'Ortiga',
  'user-admin': 'Administrador de usuarios',
  'valerian': 'Valeriana',
  'validation-visit': 'Siniestro-Preliminar',
  'validation-visit-file': 'ocurrencia',
  'validation-with-review': 'Ocurrencia con la gerencia',
  'validation-without-review': 'Ocurrencia sin gestión',
  'vanilla': 'Vainilla',
  'vegetables': 'Legumbres',
  'vegetation': 'Anomalía de vitalidad de la planta',
  'vegetative-growth': '3. Crecimiento vegetativo / Elongación del tallo',
  'verbena': 'Verbena',
  'very_good': 'Muy bueno',
  'very_poor': 'Muy malo',
  'vetch': 'Vicia',
  'vin-de-table': 'Uva (vino)',
  'walnuts': 'Nogal',
  'watermelon': 'Sandia',
  'weeds': 'Malezas',
  'wheat': 'Trigo (Blando)',
  'wheat-einkorn': 'Trigo farro  (emmer)',
  'wheat-feed': 'Trigo (ración)',
  'wheat-hard': 'Trigo (duro)',
  'wheat-hard-seeds': 'Trigo duro (semilla híbrida)',
  'wheat-seeds': 'Trigo  (semilla híbrida)',
  'wheat-top': 'Trigo (Blando - molienda mejorada)',
  'wild-animals': 'Animales salvajes',
  'wind': 'Viento',
  'winter': 'Otoño/Invierno',
  'yams': 'Ñame',
  'zucchini': 'Zapallito',
  '-': '-',
  'CropTypesSigned': 'Tipo(s) de Cultivo (Firmado por el agricultor)',
  'Telepac': 'Télépac',
  'UnknownLoss': 'Desconocido (Motivo de la pérdida)',
  'acres': 'ac',
  'added_by': 'Añadido por',
  'added_on': 'Añadido en',
  'arrobas-bra': '@/ha',
  'bags': 'sc',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'Arrobas/acre',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'brl-per-bag': 'R$/sc',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'claim_id': 'Afirmar',
  'corn-grain-white': 'Maíz (Grano) (white)',
  'corn-grain-yellow': 'Maíz (Grano) (yellow)',
  'corn-silage-white': 'Maíz (Ensilaje) (white)',
  'corn-silage-yellow': 'Maíz (Ensilaje) (yellow)',
  'crop_id': 'Tipo de cultivo',
  'custom-a': 'Vegetación mediana del municipio',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Desarrollo de las partes cosechables de las plantas',
  'development-sorghum': '4. Desarrollo de las partes cosechables de las plantas',
  'development-sugar-beet': '4. Desarrollo de las partes cosechables de las plantas',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Establecimiento',
  'feet': 'ft',
  'flowering-cereals': '6. Floración',
  'flowering-corn': '6. Floración',
  'flowering-fruits': '6. Floración',
  'flowering-grapes': '6. Floración',
  'flowering-legumes': '6. Floración',
  'flowering-rapeseed': '6. Floración',
  'flowering-sorghum': '6. Floración',
  'flowering-soybeans': '6. Floración',
  'flowering-sunflower': '6. Floración',
  'fruit-development-cereals': '7. Desarrollo de frutos',
  'fruit-development-corn': '7. Desarrollo de frutos',
  'fruit-development-fruits': '7. Desarrollo de frutos',
  'fruit-development-grapes': '7. Desarrollo de frutos',
  'fruit-development-legumes': '7. Desarrollo de frutos',
  'fruit-development-rapeseed': '7. Desarrollo de frutos',
  'fruit-development-sorghum': '7. Desarrollo de frutos',
  'fruit-development-soybeans': '7. Desarrollo de frutos',
  'fruit-development-sunflower': '7. Desarrollo de frutos',
  'germination-cereals': '0. Germinación / desarrollo de cotiledones',
  'germination-corn': '0. Germinación / desarrollo de cotiledones',
  'germination-fruits': '0. Germinación / desarrollo de cotiledones',
  'germination-grapes': '0. Germinación / desarrollo de cotiledones',
  'germination-legumes': '0. Germinación / desarrollo de cotiledones',
  'germination-rapeseed': '0. Germinación / desarrollo de cotiledones',
  'germination-sorghum': '0. Germinación / desarrollo de cotiledones',
  'germination-soybeans': '0. Germinación / desarrollo de cotiledones',
  'germination-sugar-beet': '0. Germinación / desarrollo de cotiledones',
  'germination-sunflower': '0. Germinación / desarrollo de cotiledones',
  'grams': 'gr',
  'harvest_year': 'Año de cosecha',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/há (hectolitros por hectarea)',
  'images': 'Fotos',
  'inches': 'in',
  'inflorescence-cereals': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-corn': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-fruits': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-grapes': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-legumes': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-rapeseed': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-sorghum': '5. Emergencia de la inflorescencia / Espiga',
  'inflorescence-sunflower': '5. Emergencia de la inflorescencia / Espiga',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Desarrollo foliar',
  'leaf-development-corn': '1. Desarrollo foliar',
  'leaf-development-fruits': '1. Desarrollo foliar',
  'leaf-development-grapes': '1. Desarrollo foliar',
  'leaf-development-legumes': '1. Desarrollo foliar',
  'leaf-development-rapeseed': '1. Desarrollo foliar',
  'leaf-development-sorghum': '1. Desarrollo foliar',
  'leaf-development-soybeans': '1. Desarrollo foliar',
  'leaf-development-sugar-beet': '1. Desarrollo foliar',
  'leaf-development-sunflower': '1. Desarrollo foliar',
  'loss_cause': 'Motivo de la pérdida',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Número de póliza',
  'policy_number': 'Número de póliza',
  'ripening-cereals': '8. Maduración de frutos o semillas',
  'ripening-corn': '8. Maduración de frutos o semillas',
  'ripening-fruits': '8. Maduración de frutos o semillas',
  'ripening-grapes': '8. Maduración de frutos o semillas',
  'ripening-legumes': '8. Maduración de frutos o semillas',
  'ripening-rapeseed': '8. Maduración de frutos o semillas',
  'ripening-sorghum': '8. Maduración de frutos o semillas',
  'ripening-soybeans': '8. Maduración de frutos o semillas',
  'ripening-sunflower': '8. Maduración de frutos o semillas',
  'senescence-fruits': '9. Senescencia o comienzo de la dormancia',
  'senescence-grapes': '9. Senescencia o comienzo de la dormancia',
  'senescence-sorghum': '9. Senescencia o comienzo de la dormancia',
  'senescence-soybeans': '9. Senescencia o comienzo de la dormancia',
  'senescence-sunflower': '9. Senescencia o comienzo de la dormancia',
  'side-shoot-cereals': '2. Formación de macollos',
  'side-shoot-sorghum': '2. Formación de macollos',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Peso de 1000 granos (gr)',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Datos del país',
  'user_group': 'Portafolio',
  'vegetative-growth-cereals': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-corn': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-fruits': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-legumes': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-rapeseed': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-sorghum': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-soybeans': '3. Crecimiento vegetativo / Elongación del tallo',
  'vegetative-growth-sugar-beet': '3. Crecimiento vegetativo / Elongación del tallo',
  'visit_type': 'Tipo de Inspección'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Has alcanzado el límite de ${k.count} granjas almacenadas en caché sin conexión. Elimine uno para continuar.`;
    case 'AdjusterSampledFarm': return `${k.added_by} muestreó el establecimiento ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Puede asignar otra ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Este superficie es de ${intl_num(k.area)}km² (aproximadamente ${intl_int(k.mb)}MB).`;
    case 'CurFieldPolicyDesc': return `Póliza del lote actual: ${k.policy_number}`;
    case 'CurPortfolio': return `Portafolio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Está intentando eliminar una visita que también está asignada a ${k.count} otras LA.`;
    case 'DeletedUserX': return `Usuario eliminado "${k.user_name}"`;
    case 'DistKmAway': return `${intl_num(k.dist_km)}km`;
    case 'DownloadExplain': return `Seleccionar un área para descargar para usar sin conexión. Área máxima: ${intl_int(k.limit_km_2)} km². Si es necesario, descargue varias zonas.`;
    case 'DuplicateFieldId': return `El lote ${k.external_field_id} ya há sido agregado.`;
    case 'DuplicateSubplotWarning': return `Ya existe un Sub-lote ID: ${k.external_harvest_id} para esta granja y Año de cosecha. Para agregarle una muestra, cambie su selección en la página anterior o elija otra ID de trama secundaria.`;
    case 'EarsDetectedCount': return `${k.count} oídos detectados`;
    case 'ErrorDeletingUserXY': return `Error al eliminar "${k.user_name}": ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `El rendimiento estimado se anuló por última vez el ${k.date}. Tenga en cuenta que esta modificación reemplaza el rendimiento estimado agregado de las muestras que se muestran a continuación.`;
    case 'EstimatedYieldValueUnit': return `Estimado: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `La cosecha existente contiene ${k.count} muestra(s).`;
    case 'ExoCropUnsupported': return `Esta característica actualmente no admite ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Realizable: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Advertencia: esta muestra se agregará solo al primero de los ${intl_int(k.numFields)} agregados.`;
    case 'FileSizeTooBig': return `Este archivo es demasiado grande. Seleccione un archivo de menos de ${k.file_size}MB.`;
    case 'ImageLocationTooFar': return `La foto #${k.imageIdx} fue sacada a ${intl_num(k.distanceMeters)}` + t(k.unit) + ` de su lugar de muestreo.`;
    case 'InsuredYieldValueUnit': return `Asegurado: ${intl_num_2(k.val)}` + t(k.unit);
    case 'InvalidBoolError': return `Este valor no se pudo analizar como un valor booleano: ${k.val}`;
    case 'LossValue': return `Pérdida: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `La suma de las pérdidas es demasiado grande: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` sobre la estimación.`;
    case 'LossesToAllocate': return `Pérdidas a asignar: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` de ${intl_num_2(k.total)}` + t(k.unit) + ` pérdida total.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} establecimientos seleccionados`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} pólizas seleccionadas`;
    case 'NoAppInstalledToViewFile': return `No hay ninguna aplicación instalada para ver ${k.file_name}`;
    case 'NoDeleteClaim': return `Antes de eliminar este reclamo, debe eliminar sus ${intl_int(k.visitCount)} visitas.`;
    case 'NoDeleteFarm': return `Antes de eliminar esta granja, debe eliminar sus campos ${intl_int(k.fieldCount)}, muestras ${intl_int(k.sampleCount)} y reclamaciones ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Antes de eliminar este lote, debe eliminar sus ${intl_int(k.sampleCount)} muestras.`;
    case 'NoDeleteHarvest': return `Antes de borrar la cosecha, debe borrar las ${intl_int(k.sampleCount)} muestras.`;
    case 'NoDeletePolicy': return `Antes de eliminar esta política, debe eliminar sus ${intl_int(k.claimCount)} reclamaciones.`;
    case 'NonUniqueFarm': return `Se detectaron dos granjas para ${k.key}, pero con diferentes detalles: '${k.x1}' vs '${k.x2}'.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} Fotos`;
    case 'OfflineAreaDownloadMore': return `Quedan ${k.count} áreas sin conexión.`;
    case 'OfflineAreaMaxLimit': return `Has alcanzado el límite de ${k.count} áreas. Deselecciona algunas áreas para continuar.`;
    case 'PasswordResetSent': return `Se ha enviado un correo electrónico de restablecimiento a ${k.email}`;
    case 'PlantDensityValueUnit': return `Densidad de plantas: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Póliza ${k.policy_number}`;
    case 'PredictedYieldValueUnit': return `Predicho: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ReportContainsTooManyFields': return `Este reporte puede ser muy pesado, con ${intl_int(k.numFields)} lotes. Considere seleccionar una sub-muestra de fechas y cultivos.`;
    case 'ReportNoHarvests': return `¡Este establecimiento tiene ${intl_int(k.numFields)} lotes, pero no tiene ninguna información de cultivos para reportar!`;
    case 'ResetMsg': return `Se le ha enviado un correo electrónico a ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `El lugar de muestreo está a ${intl_num_2(k.val)}` + t(k.unit) + ` del lugar de inicio. Caso quiera usar su localización, presione "Use mi ubicación" arriba.`;
    case 'SampleYieldWarning': return `Por favor complete ${k.column1} o ${k.column2}, si es posible.`;
    case 'SearchAddTipDesc': return `Si desea buscar varios términos (p. ej. cosecha de trigo en portafolio ${k.firstUserGroup}) puedes presionar en el ⊕ icono para agregar un término adicional.`;
    case 'SearchExampleDescUserGroup': return `Escriba un nombre de portafolio (e.g. ${k.firstUserGroup}) para mostrar todos los elementos de ese portafolio`;
    case 'SearchFoundCount': return `Encontró ${intl_int(k.farmCount)} establecimientos, ${intl_int(k.fieldCount)} lotes, y ${intl_int(k.sampleCount)} muestras.`;
    case 'SearchInfoText': return `Puede encontrar establecimientos, lotes, muestras y pólizas buscándolos aquí. Si desea buscar varios términos (p. ej. cosecha de trigo en portafolio ${k.firstUserGroup}) puede presionar en el ⊕ icono. Cada búsqueda actuará como un filtro que reducirá aún más los resultados. Consulte los ejemplos a continuación para conocer algunas formas de búsqueda.`;
    case 'SearchResultAddedBy': return `muestras agregadas por ${k.id}`;
    case 'SearchResultAddress': return `Centrar mapa en ${k.address}`;
    case 'SearchResultCropCondition': return `muestras de cultivos con condición ${k.id}`;
    case 'SearchResultFarm': return `establecimiento ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `elementos para ${k.id}`;
    case 'SearchResultHarvestSeason': return `elementos para cosechas en ` + t(k.id);
    case 'SearchResultHarvestYear': return `elementos para cosechas de ${k.id}`;
    case 'SearchResultUserGroup': return `Portafolio ${k.id}`;
    case 'SelectCount': return `${k.count} seleccionado`;
    case 'SortBy': return `Ordenar ${k.column} ` + t(k.id);
    case 'SubsidyError_SUBSIDY_CONFIG_NOT_FOUND': return `Falta la configuración de subsidio para la cartera ${k.user_group}.`;
    case 'SureAddAnotherHarvest': return `¿Está seguro de que desea agregar otra cosecha a este lote, además de ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `¿Estás seguro de que deseas eliminar ${k.file_name}?`;
    case 'SureDeletePolicy': return `¿Está seguro de que desea eliminar esta póliza? Esta póliza se eliminará de ${intl_int(k.fieldCount)} lotes y ${intl_int(k.sampleCount)} muestras, pero ellas no serán eliminadas.`;
    case 'SureRemoveFarm': return `¿Está seguro de que desea eliminar este establecimiento y sus ${intl_int(k.fieldCount)} lotes?`;
    case 'SureRemoveSelectedOfflineFarms': return `¿Está seguro de que desea eliminar el acceso sin conexión a ${k.count} granjas seleccionadas?`;
    case 'TelepacReportEmail': return `Un reporte ha sido enviado a su ${k.email}, también puede bajarlo aqui.`;
    case 'TotalAffectedAreaHarvest': return `El área total afectada para esta cosecha será ${intl_num_2(k.newArea)}` + t(k.unit) + ` sobre un área de cosecha de ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `La superficie afectada actual (muestreada en las últimas dos semanas) para esta campaña es de: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Esta es toda la superficie afectada para esta campaña: ${k.arr}.`;
    case 'TotalLossPct': return `Pérdida total: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `El área total de la subparcela (${intl_num_2(k.total_subplot_area)} ha) es mayor que el área del campo (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Esto actualizará el Area del Cultivo de la cosecha actual (${k.desc}) a ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Este campo se asignará a la cosecha de la granja "${k.upgradedHarvest}".`;
    case 'WarnHarvestAlreadyInUse': return `Guardar los cambios en esta recopilación existente afectaría a ${intl_int(k.num_samples)} muestras ya existentes.`;
    case 'WarningDupePolicyNumber': return `Atención: ya existe una póliza con el número ${k.policy_number}`;
    case 'YieldMismatchWarning': return `No se contabilizan todos los daños: falta ${intl_num_2(k.deltaVal)}` + t(k.unit) + `.`;
    case 'YourAreGoingToDeleteUsers': return `Vas a eliminar ${intl_int(k.num_users)} usuarios:`;
    case 'downloadingLayers': return `Descargando imágenes de satélite ${intl_int(k.count)} para usar sin conexión.`;
    case 'uploadingImages': return `Enviando fotos a la base de datos  (${intl_int(k.left)} left).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}